import { useEffect, useMemo, useRef } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../../conf/firebaseConfig";
import {
  genericConverter,
  markAllMessagesRead,
} from "../../services/firestoreService";
import { useIonViewDidLeave } from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Message,
  MessageCreateRequest,
} from "@futurhealth/steadymd-api-client";
import { useAuth } from "../../context/AuthContext";
import { useAuthToken } from "../useAuthToken";
import { createMessage } from "../../services/steadymdService";
import { FirestoreMessage } from "../../typings/steadymdTypes";
import { MessageRecipient } from "../../utils/messageUtils";

// Fetch initial messages for a given userId
const fetchMessages = async (userId: string) => {
  if (!userId) return [];
  const q = query(
    collection(db, "steadymd.messages"),
    where("userId", "==", userId),
    orderBy("sentAt", "desc"),
    limit(100)
  ).withConverter(genericConverter<FirestoreMessage>());

  // Execute the query
  const res = await getDocs(q);
  return res.docs.map((doc) => doc.data());
};

const fetchUnreadMessages = async (userId: string) => {
  if (!userId) return [];
  const q = query(
    collection(db, "steadymd.messages"),
    where("userId", "==", userId),
    where("read", "==", false),
    orderBy("sentAt", "desc")
  ).withConverter(genericConverter<FirestoreMessage>());

  const res = await getDocs(q);
  return res.docs.map((doc) => doc.data());
};

export const useMessagesRealtime = (userId: string) => {
  const queryKey = useMemo(
    () => ["steadymd", "messages", { userId }],
    [userId]
  );
  const queryClient = useQueryClient();

  const latestUnsub = useRef<(() => void) | undefined>(undefined); // Using useRef to keep track of unsubscribe function

  // Query for initial messages
  const initialQuery = useQuery({
    queryKey: queryKey,
    queryFn: () => fetchMessages(userId),
    enabled: !!userId,
  });

  useEffect(() => {
    if (!userId) {
      // Ensure cleanup when no user is logged in
      latestUnsub?.current?.();
      latestUnsub.current = undefined;
      return;
    }

    // If there's an existing subscription, unsubscribe from it before setting a new one
    latestUnsub?.current?.();

    // Subscribe to real-time updates on the collection
    const q = query(
      collection(db, "steadymd.messages"),
      where("userId", "==", userId),
      orderBy("sentAt", "desc"),
      limit(100)
    ).withConverter(genericConverter<FirestoreMessage>());

    latestUnsub.current = onSnapshot(
      q,
      (snapshot) => {
        const allMessages = snapshot.docs.map((doc) => doc.data());
        queryClient.setQueryData(queryKey, allMessages);
      },
      (error) => {
        console.error("Error fetching messages updates: ", error);
      }
    );

    return () => {
      latestUnsub?.current?.();
      latestUnsub.current = undefined;
    };
  }, [queryKey, userId, queryClient]);

  // Clean up subscription on unmount
  useIonViewDidLeave(() => {
    if (latestUnsub.current) {
      latestUnsub.current();
      latestUnsub.current = undefined;
    }
  });

  return initialQuery;
};

export const useUnreadMessagesQuery = () => {
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  return useQuery({
    queryKey: ["steadymd", "messages", { userId, unreadOnly: true }],
    queryFn: () => fetchUnreadMessages(userId ?? ""),
    enabled: !!userId,
  });
};

export const useMarkMessagesAsReadMutation = () => {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const userId = currentUser?.uid;

  return useMutation({
    mutationFn: async () => {
      return markAllMessagesRead(userId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["steadymd", "messages"] });
    },
  });
};

export const useSendMessageMutation = () => {
  const { currentUser } = useAuth();
  const token = useAuthToken();
  const queryClient = useQueryClient();
  const userId = currentUser?.uid;

  return useMutation({
    mutationFn: async ({ text, episodeGuid }: MessageCreateRequest) => {
      const signal = new AbortController();
      const cancelRequestTimeout = setTimeout(() => {
        signal.abort();
      }, 30000);

      if (!token || !userId) {
        console.log("Failed to create message: token or user id not found");
        throw new Error("Something went wrong");
      }

      try {
        return await createMessage(
          token ?? "",
          userId ?? "",
          episodeGuid,
          text,
          signal.signal
        );
      } finally {
        clearTimeout(cancelRequestTimeout);
      }
    },
    onMutate: async ({ text, episodeGuid }) => {
      // Snapshot previous state before updating
      await queryClient.cancelQueries({
        queryKey: ["steadymd", "messages", { userId }],
      });
      const previousMessages = queryClient.getQueryData<Message[]>([
        "steadymd",
        "messages",
        { userId },
      ]);

      queryClient.setQueryData(
        ["steadymd", "messages", { userId }],
        (oldData: Message[] | undefined) => {
          if (!oldData) return [];
          return [
            {
              userId,
              text,
              episodeGuid,
              sentAt: new Date().toISOString(),
              recipient: MessageRecipient.Clinician,
            },
            ...oldData,
          ];
        }
      );

      return { previousMessages };
    },
    onError: (_, __, context) => {
      // Rollback to the previous state
      if (context?.previousMessages) {
        queryClient.setQueryData(
          ["steadymd", "messages", { userId }],
          context.previousMessages
        );
      }
    },
  });
};
