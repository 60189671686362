import {
  ConsultCreate,
  EpisodeCreateRequest,
  IntakeObservationRequest,
  IntakeQuestionnaireInner,
  IntakeRequest,
  PatientRequest,
  Intake,
  ConsultStatusChangeNewStatusEnum,
  Consult,
  PatientUI,
  PatientUIRequest,
  EpisodeCreateRequestPharmacy,
  Patient,
} from "@futurhealth/steadymd-api-client";
import axios from "axios";
import { SMDClinician, SMDEpisode } from "../typings";
import { User } from "firebase/auth";

export const STEADY_MD_PROGRAM_GUID = import.meta.env
  .VITE_STEADY_MD_PROGRAM_GUID;

const steadymdCloudFunctionsBaseURL = `${import.meta.env.VITE_API_GATEWAY_URL}/steadymd`;

export const preferredMedicationNameQuestionData = {
  category: "",
  description: "",
  index: -1,
  question: "Offered Bundle",
  question_type: "",
  slug: "preferred_medication_name",
};

export const getSteadymdPatient = async (
  patientId: string | undefined,
  user: User | null
) => {
  if (!patientId) throw new Error("User is not authenticated");
  if (!user) throw new Error("User is not authenticated");
  const token = await user.getIdToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { patientId },
  };

  const response = await axios.get<Patient>(
    `${steadymdCloudFunctionsBaseURL}/patient/retrieve`,
    config
  );
  return response.data;
};

export const createSteadymdPatient = async (
  patient: PatientRequest,
  token: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const payload = { ...patient, address2: patient.address2 || "" };

  const response = await axios.post(
    `${steadymdCloudFunctionsBaseURL}/patient/create`,
    payload,
    config
  );
  return response.data;
};

export const createSteadymdEpisode = async (
  episode: EpisodeCreateRequest,
  token: string
) => {
  const pharmacy: EpisodeCreateRequestPharmacy = {
    ncpdpId: "5756917",
    npiNumber: "1316684616",
    phoneNumber: "305-731-2318",
    address1: "15600 NW 15th Ave, Suite C",
    city: "Miami Gardens",
    state: "FL",
    zipCode: "33169",
    storeName: "The Pharmacy HUB LLC",
    primaryFax: "305-548-2454",
    isDefault: true,
    isPreferred: true,
  };
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(
    `${steadymdCloudFunctionsBaseURL}/episode/create`,
    { ...episode, pharmacy },
    config
  );
  return response.data;
};

export const updateSteadymdEpisode = async (
  episodeId: string,
  updatedData: Partial<SMDEpisode>,
  token: string
) => {
  if (!episodeId) {
    throw new Error("episodeId is required");
  }
  if (!updatedData) {
    throw new Error("updatedData is required");
  }
  const url = `${steadymdCloudFunctionsBaseURL}/episode/update?episodeId=${episodeId}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
    const response = await axios.patch<{ guid: string }>(
      url,
      updatedData,
      config
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to update SteadyMD episode: ${(error as Error).message}`
    );
  }
};

export const createSteadymdConsult = async (
  consult: ConsultCreate,
  token?: string
): Promise<Consult> => {
  if (!token) throw new Error("User is not authenticated");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(
    `${steadymdCloudFunctionsBaseURL}/consult/create`,
    consult,
    config
  );
  return response.data;
};

export const scheduleSteadymdConsult = async (
  consultId: string,
  startsAt: string,
  timezone: string,
  token: string
): Promise<Consult> => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(
    `${steadymdCloudFunctionsBaseURL}/consult/schedule`,
    { consultId, startsAt, timezone },
    config
  );
  return response.data;
};

export const updateSteadymdConsultStatus = async (
  consultId: string,
  status: ConsultStatusChangeNewStatusEnum,
  token: string
): Promise<Consult> => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { consultId },
  };

  const response = await axios.post(
    `${steadymdCloudFunctionsBaseURL}/consult/update-status`,
    { status },
    config
  );
  return response.data;
};

export const createSteadymdIntake = async (
  episodeGuid: string,
  observations: Array<IntakeObservationRequest>,
  questionnaire: Array<IntakeQuestionnaireInner>,
  labRequisitionGuid: string | null,
  isDraft: boolean,
  token: string
) => {
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await axios.post(
    `${steadymdCloudFunctionsBaseURL}/intake/create`,
    { episodeGuid, observations, questionnaire, labRequisitionGuid, isDraft },
    config
  );
  return response.data;
};

export const getConsultAvailabilities = async (
  consultId: string,
  token: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { consultId },
  };

  const response = await axios.get(
    `${steadymdCloudFunctionsBaseURL}/consult-availabilities/retrieve`,
    config
  );

  return response.data;
};

export const getAvailabilitiesForProgram = async (
  usState: string,
  consultType: string,
  token: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { usState, consultType },
  };

  const response = await axios.get(
    `${steadymdCloudFunctionsBaseURL}/program-consult-availabilities/retrieve`,
    config
  );

  return response.data;
};

export const updateIntake = async (
  intakeId: string,
  updatedIntake: IntakeRequest,
  token: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { intakeId },
  };

  const response = await axios.post<Intake>(
    `${steadymdCloudFunctionsBaseURL}/intake/update`,
    updatedIntake,
    config
  );

  return response.data;
};

export const updateConsultStatus = async (
  token: string,
  consultId: string,
  postBody: { status: string | null }
) => {
  const url = `${steadymdCloudFunctionsBaseURL}/consult/update-status?consultId=${consultId}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post<{
    guid: string;
  }>(url, postBody, config);
  return response.data;
};

export const generatePatientUIURLForVisit = async (
  token: string,
  consultId: string,
  postBody: PatientUIRequest
) => {
  const url = `${steadymdCloudFunctionsBaseURL}/patient-ui-url/create`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { consultId },
  };
  const response = await axios.post<PatientUI>(url, postBody, config);
  return response.data;
};

export const getLabOrderPDF = async (labOrderId: string, token: string) => {
  const url = `${steadymdCloudFunctionsBaseURL}/lab-order-pdf/retrieve`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { labOrderId },
  };

  const response = await axios.get(url, config);
  return response.data;
};

export const getLabOrderResultPDF = async (
  labOrderId: string,
  labOrderResultId: string,
  token: string
) => {
  const url = `${steadymdCloudFunctionsBaseURL}/lab-order-result-pdf/retrieve`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { labOrderId, labOrderResultId },
  };

  const response = await axios.get(url, config);
  return response.data;
};

export const retrieveClinician = async (token: string, consultId: string) => {
  const url = `${steadymdCloudFunctionsBaseURL}/clinician/retrieve?clinicianId=${consultId}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get<SMDClinician>(url, config);
  return response.data;
};

export const createMessage = async (
  token: string,
  userId: string,
  episodeGuid: string,
  messageText: string,
  signal: AbortSignal
) => {
  const url = `${steadymdCloudFunctionsBaseURL}/message/create`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(
    url,
    { userId, episodeGuid, messageText },
    { ...config, signal }
  );
  return response.data;
};

export const getPreferredMedicationNameAnswer = (medicationName: string) => {
  return {
    type: "text",
    answerType: "valueString",
    question: "Offered Bundle",
    answer: [medicationName],
  } as IntakeQuestionnaireInner;
};
