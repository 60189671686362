import { isPlatform } from "@ionic/core";
import { Browser, OpenOptions } from "@capacitor/browser";

export type IABOptions = Pick<
  OpenOptions,
  "windowName" | "toolbarColor" | "presentationStyle"
>;

class InAppBrowser {
  open(url: string | undefined | null, options: IABOptions = {}) {
    if (!url) {
      return;
    }

    if (isPlatform("capacitor")) {
      return Browser.open(Object.assign({ url }, options));
    }

    return window.open(url);
  }
}

const inAppBrowser = new InAppBrowser();
export default inAppBrowser;
