import { useQuery } from "@tanstack/react-query";
import { useAuthToken } from "../useAuthToken";
import {
  checkSingleFollowupExists,
  getAllConsultsByType,
} from "../../services/firestoreService";
import { ConsultType } from "../../typings/enums";

const determineLabsRequired = async (userId: string) => {
  const singleFollowUpVisitExists = await checkSingleFollowupExists(userId);
  if (!singleFollowUpVisitExists) {
    return false;
  }
  const asyncLabOrderConsults = await getAllConsultsByType(
    userId,
    ConsultType.async_lab_order
  );

  const noResultsReady = asyncLabOrderConsults.every(
    (consult) => consult.visitStatus !== "lab_results_ready"
  );

  return noResultsReady;
};

export const useLabsRequiredQuery = (userId: string) => {
  const token = useAuthToken();
  return useQuery({
    queryKey: ["firestore", "labs required", { user: userId }],
    queryFn: () => determineLabsRequired(userId),
    enabled: !!userId && !!token,
  });
};
