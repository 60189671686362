import { useQuery } from "@tanstack/react-query";
import { getLatestSMDPrescription } from "../../services/firestoreService";
import { useAuth } from "../../context/AuthContext";

export const useLatestPrescriptionQuery = () => {
  const { currentUser } = useAuth();

  return useQuery({
    queryKey: [
      "firestore",
      "latest prescription",
      { userId: currentUser?.uid },
    ],
    queryFn: () => getLatestSMDPrescription(currentUser?.uid || ""),
    enabled: !!currentUser?.uid,
  });
};
