// Page view constants
export const BOOKING_INTRO_PAGE_VIEW = "booking_intro_page_view";
export const SCHEDULE_APPOINTMENT_PAGE_VIEW = "schedule_appointment_page_view";
export const TELEMEDICINE_AGREEMENT_PAGE_VIEW =
  "telemedicine_agreement_page_view";
export const THANK_YOU_PAGE_VIEW = "thank_you_page_view";
export const TERMS_AGREEMENT_PAGE_VIEW = "terms_agreement_page_view";
export const APPROVAL_PAGE_VIEW = "approval_page_view";
export const RESULTS_PAGE_VIEW = "results_page_view";
export const PAYMENT_PAGE_VIEW = "payment_page_view";
export const ADDRESS_PAGE_VIEW = "address_page_view";
export const ACCOUNT_CREATE_PAGE_VIEW = "account_create_page_view";

// DeepLinks constants
export const SCHEDULE_APPOINTMENT_LINK_CREATION_FAILED =
  "schedule_appointment_link_creation_failed";
export const PAYMENT_LINK_CREATION_FAILED =
  "payment_method_link_creation_failed";
export const APPOINTMENT_VIDEO_LINK_CREATION_FAILED =
  "appointment_video_link_creation_failed";
export const CREATE_PASSWORD_LINK_CREATION_FAILED =
  "create_password_link_creation_failed";

// Event constants
export const ACCOUNT_CREATED_SUCCESS = "account_created_success";
export const ACCOUNT_CREATED_FAILURE = "account_created_failure";

export const APPOINTMENT_BOOKED_SUCCESS = "appointment_booked_success";
export const APPOINTMENT_BOOKED_FAILURE = "appointment_booked_failure";

export const MEDS_RESERVED_SUCCESS = "meds_reserved_success";
export const MEDS_RESERVED_FAILURE = "meds_reserved_failure";

export const CREATE_INTAKE_SUCCESS = "create_intake_success";
export const CREATE_INTAKE_FAILURE = "create_intake_failure";

export const PLAN_PURCHASE_SUCCESS = "plan_purchase_success";
export const PLAN_PURCHASE_FAILURE = "plan_purchase_failure";

export const LOADER_QUESTION_INITIATED = "loader_question_initiated";

export const PORTAL_LOGIN = "portal_login";

// Survey Events

export const SURVEY_STARTED = "survey_started";
export const SURVEY_QUESTION = "survey_question";
export const SURVEY_COMPLETED = "survey_completed";

// Payment Events
export const PAYMENT_ELEMENT_SUBMISSION_INITIATED =
  "payment_element_submission_initiated";
export const PAYMENT_ELEMENT_SUBMISSION_SUCCESS =
  "payment_element_submission_success";
export const PAYMENT_ELEMENT_SUBMISSION_ERROR =
  "payment_element_submission_error";
export const STRIPE_EXPRESS_CHECKOUT_INITIATED =
  "stripe_express_checkout_initiated";
export const STRIPE_EXPRESS_CHECKOUT_ERROR = "stripe_express_checkout_error";
export const STRIPE_EXPRESS_CHECKOUT_SUCCESS =
  "stripe_express_checkout_success";
export const GR4VY_CHECKOUT_ATTEMPT = "gr4vy_checkout_attempt";
export const GR4VY_CHECKOUT_INITIATED = "gr4vy_checkout_initiated";
export const GR4VY_CHECKOUT_ERROR = "gr4vy_checkout_error";
export const GR4VY_CHECKOUT_SUCCESS = "gr4vy_checkout_success";

export const GR4VY_APPLEPAY_CHECKOUT_DISPLAYED =
  "gr4vy_applepay_checkout_displayed";
export const GR4VY_APPLEPAY_CHECKOUT_ATTEMPT =
  "gr4vy_applepay_checkout_attempt";
export const GR4VY_APPLEPAY_CHECKOUT_SUCCESS =
  "gr4vy_applepay_checkout_success";
export const GR4VY_APPLEPAY_CHECKOUT_ERROR = "gr4vy_applepay_checkout_error";

export const GR4VY_GOOGLEPAY_CHECKOUT_ATTEMPT =
  "gr4vy_googlepay_checkout_attempt";
export const GR4VY_GOOGLEPAY_CHECKOUT_SUCCESS =
  "gr4vy_googlepay_checkout_success";
export const GR4VY_GOOGLEPAY_CHECKOUT_ERROR = "gr4vy_googlepay_checkout_error";

export const GR4VY_AFFIRM_CHECKOUT_ATTEMPT = "gr4vy_affirm_checkout_attempt";
export const GR4VY_AFFIRM_CHECKOUT_SUCCESS = "gr4vy_affirm_checkout_success";
export const GR4VY_AFFIRM_CHECKOUT_FAILED = "gr4vy_affirm_checkout_failed";
export const GR4VY_AFFIRM_CHECKOUT_ERROR = "gr4vy_affirm_checkout_error";
export const GR4VY_AFFIRM_REDIRECT_TO = "gr4vy_affirm_redirect_to";
export const GR4VY_AFFIRM_REDIRECT_BACK = "gr4vy_affirm_redirect_back";
export const AFFIRM_REJECT_PAGE_VIEW = "affirm_reject_page_view";
