import { useMemo, useState } from "react";
import {
  Drawer,
  DrawerRootProps,
  Stack,
  Text,
  ActionIcon,
} from "@mantine/core";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FHButton from "../components/FHButton";
import { colors } from "../theme/colors";
import { openZendesk } from "../services/zendeskService";
import { FirestoreMessage } from "../typings/steadymdTypes";
import { useLatestRefillSessionByUser } from "../hooks/react-query/useRefillSession";
import { useSideEffectConsultQuery } from "../hooks/react-query/useConsultQuery";
import { useAuth } from "../context/AuthContext";
import { TreatmentPlanState } from "./treatmentPlanUtils";

export const StartConversation = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleContactSupportClick = () => {
    handleCloseDrawer();
    openZendesk();
  };

  return (
    <Stack gap={10} w="100%" mt="auto">
      <Text fz="14px" c={colors.gray} mx="auto">
        Can we help you with something?
      </Text>
      <FHButton onClick={handleOpenDrawer}>Start a Conversation</FHButton>
      <StartConversationDrawer
        opened={drawerOpen}
        onClose={handleCloseDrawer}
        onContactSupportClick={handleContactSupportClick}
      />
    </Stack>
  );
};

type DrawerProps = { onContactSupportClick: () => void } & Pick<
  DrawerRootProps,
  "opened" | "onClose"
>;

const StartConversationDrawer = ({
  opened,
  onClose,
  onContactSupportClick,
}: DrawerProps) => (
  <Drawer.Root
    opened={opened}
    onClose={onClose}
    position="bottom"
    radius={"lg"}
  >
    <Drawer.Overlay />
    <Drawer.Content>
      <Drawer.Body h="100%" pb="xl">
        <Stack align="center" justify="center" w="100%" h="100%" px="sm">
          <Text fz="28px" fw={600}>
            Start a Conversation
          </Text>
          <Text fz="16px" style={{ textAlign: "center" }}>
            Select what you need help with and we&apos;ll connect you with
            someone who can assist.
          </Text>
          <Stack w="100%" gap={"10px"} mb={"md"}>
            <FHButton
              // onClick={handleNavigate("/side-effects-intake")}
              fullWidth
              variant="tertiary"
            >
              Report a Side Effect
            </FHButton>
            <ContactSupportButton onClick={onContactSupportClick} />
          </Stack>
          <FHButton onClick={onClose} fullWidth mt="auto">
            Cancel
          </FHButton>
        </Stack>
      </Drawer.Body>
    </Drawer.Content>
  </Drawer.Root>
);

type ContactSupportButtonProps = {
  onClick?: () => void;
};

export const ContactSupportButton = ({
  onClick,
}: ContactSupportButtonProps) => {
  const handleClick = () => {
    onClick?.();
    openZendesk();
  };
  return (
    <ActionIcon
      w="60px"
      h="60px"
      radius="xl"
      color={colors.white}
      style={{
        boxShadow: "0 4px 20px rgba(17, 17, 17, 0.1)",
      }}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        icon={faQuestion}
        fontSize="23px"
        color={colors.blurple}
      />
    </ActionIcon>
  );
};

export enum StatusMessage {
  SideEffects = "Side Effects Report Submitted",
  CheckInReview = "Refill Intake Submitted",
}

export enum MessageRecipient {
  Clinician = "to_clinician",
  Patient = "to_patient",
}

export const useStatusMessage = (
  treatmentPlanState: TreatmentPlanState,
  messages?: FirestoreMessage[]
) => {
  const { currentUser } = useAuth();
  const { data: sideEffectConsult } = useSideEffectConsultQuery(
    currentUser?.uid || ""
  );

  const { data: latestCompleteRefillSession } = useLatestRefillSessionByUser(
    currentUser?.uid || "",
    true
  );

  const lastMessageFromClinician = useMemo(() => {
    return messages?.find(
      (message) => message.recipient === MessageRecipient.Patient
    );
  }, [messages]);

  if (!messages) return null;

  let sentAtDate = null;
  let messageText = "";

  switch (treatmentPlanState) {
    case TreatmentPlanState.SideEffects:
      sentAtDate = sideEffectConsult?.createdAt
        ? new Date(sideEffectConsult.createdAt)
        : null;
      messageText = StatusMessage.SideEffects;
      break;
    case TreatmentPlanState.CheckInReview:
      sentAtDate = latestCompleteRefillSession?.updatedAt
        ? latestCompleteRefillSession?.updatedAt.toDate()
        : null;
      messageText = StatusMessage.CheckInReview;
      break;
    default:
      return null;
  }

  if (!sentAtDate) return null;

  const isRecentMessageFromClinician =
    lastMessageFromClinician?.sentAt &&
    sentAtDate.getTime() < new Date(lastMessageFromClinician.sentAt).getTime();

  if (isRecentMessageFromClinician) return null;

  const statusMessage: FirestoreMessage = {
    recipient: MessageRecipient.Clinician,
    text: messageText,
    sentAt: sentAtDate.toISOString(),
    userId: currentUser?.uid || "",
    createdAtDT: sentAtDate.toISOString(),
    sentAtDT: sentAtDate.toISOString(),
    episodeGuid: "",
    guid: "",
    createdBy: 0,
  };

  return statusMessage;
};
