import { useQuery } from "@tanstack/react-query";
import {
  getConsult,
  getConsultByReason,
  getLatestSyncConsult,
  getFollowUpOrSideEffectConsult,
  getReferredOutConsult,
  getRefillConsult,
} from "../../services/firestoreService";
import { ConsultReasonForVisitEnum } from "@futurhealth/steadymd-api-client/dist/api";
import { useAuth } from "../../context/AuthContext";

export const useConsultQuery = (consultId: string) => {
  return useQuery({
    queryKey: ["firestore", "consult", { consultId }],
    queryFn: () => getConsult(consultId),
    enabled: !!consultId,
  });
};

export const useRefillConsultQuery = (userId: string) => {
  return useQuery({
    queryKey: ["firestore", "latest refill consult", { userId }],
    queryFn: () => getRefillConsult(userId),
    enabled: !!userId,
  });
};

export const useRefillOrSideEffectConsultQuery = (userId: string) => {
  return useQuery({
    queryKey: [
      "firestore",
      "latest follow up or side effect consult",
      { userId },
    ],
    queryFn: () => getFollowUpOrSideEffectConsult(userId),
    enabled: !!userId,
  });
};

export const useReferredOutConsultQuery = (userId: string) => {
  return useQuery({
    queryKey: ["firestore", "referred out consult", { userId }],
    queryFn: () => getReferredOutConsult(userId),
    enabled: !!userId,
  });
};

export const useSideEffectConsultQuery = (userId: string) => {
  return useQuery({
    queryKey: ["firestore", "side effect consult", { userId }],
    queryFn: () =>
      getConsultByReason(userId, ConsultReasonForVisitEnum.SideEffect),
    enabled: !!userId,
  });
};

export const useLatestSyncConsult = (enabled: boolean = true) => {
  const { currentUser } = useAuth();

  return useQuery({
    queryKey: [
      "firestore",
      "latest sync consult",
      { userId: currentUser?.uid },
      enabled,
    ],
    queryFn: () => getLatestSyncConsult(currentUser?.uid),
  });
};
