import { AffirmTransactionStatus } from "../../typings/enums";

const isTransactionFailed = (status: string): boolean => {
  return status === AffirmTransactionStatus.Failed;
};

const isTransactionDeclined = (status: string): boolean => {
  return status === AffirmTransactionStatus.RequiresAction;
};

const isTransactionSuccess = (status: string): boolean => {
  return status === AffirmTransactionStatus.Succeeded;
};

export { isTransactionSuccess, isTransactionFailed, isTransactionDeclined };
