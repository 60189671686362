import React, { useCallback, useEffect } from "react";
import { Route, RouteProps, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "../hooks/useQuery";
import { useUserRealTime } from "../hooks/useUserRealTime";
import { AffirmTransactionStatus, CheckoutRoutes } from "../typings/enums";
import { usePersistStore } from "../store/persistStore";

interface CheckoutApprovalRouteProps extends RouteProps {
  component: React.ComponentType<object>;
}

const isCheckoutRoute = (pathname: string): boolean => {
  return Object.values(CheckoutRoutes).includes(pathname as CheckoutRoutes);
};

// The following routing rulles are for the following routes:
// Approval = "/approval",

const CheckoutApprovalRoute: React.FC<CheckoutApprovalRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const query = useQuery();
  const surveySessionId = query.get("surveySessionId") ?? "";
  const { currentUser } = useAuth();
  const user = useUserRealTime(currentUser?.uid ?? "");
  const location = useLocation();
  const history = useHistory();
  const { affirmTransactionStatus } = usePersistStore.getState();

  const handleRedirection = useCallback(() => {
    const isAffirmTransactionSuccessful =
      affirmTransactionStatus === AffirmTransactionStatus.Succeeded;
    const hasUserPaid =
      isAffirmTransactionSuccessful || !!user?.paymentsService;
    const hasCreatedAccount = !!user?.hasCreatedAccount;

    const shouldRedirectToAccount = (hasUserPaid: boolean) => {
      return (
        (!hasCreatedAccount &&
          location.pathname === CheckoutRoutes.Account &&
          hasUserPaid) ||
        (surveySessionId &&
          hasUserPaid &&
          isCheckoutRoute(location.pathname) &&
          location.pathname !== CheckoutRoutes.Account)
      );
    };

    const shouldRedirectToHome = () => {
      return isCheckoutRoute(location.pathname) && !surveySessionId;
    };

    const shouldRedirectToAppointment = (
      hasCreatedAccount: boolean,
      hasUserPaid: boolean
    ) => {
      return (
        isCheckoutRoute(location.pathname) && hasCreatedAccount && hasUserPaid
      );
    };

    const redirectToAccount = () => {
      history.replace(
        `${CheckoutRoutes.Account}?surveySessionId=${surveySessionId}`
      );
    };

    const redirectToHome = () => {
      history.replace("/tabs/home");
    };

    const redirectToAppointment = () => {
      history.replace("/create-appointment/0");
    };

    if (shouldRedirectToAccount(hasUserPaid)) {
      redirectToAccount();
    } else if (shouldRedirectToHome()) {
      redirectToHome();
    } else if (shouldRedirectToAppointment(hasCreatedAccount, hasUserPaid)) {
      redirectToAppointment();
    }
  }, [
    affirmTransactionStatus,
    user,
    location.pathname,
    surveySessionId,
    history,
  ]);

  useEffect(() => {
    handleRedirection();
  }, [handleRedirection]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default CheckoutApprovalRoute;
