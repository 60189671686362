import { generatePatientUIURLForVisit } from "../services/steadymdService";
import { useState } from "react";
import { useAuth } from "../context/AuthContext";

const SURVEY_URL = "https://www.surveymonkey.com/r/CT5XZPX";

export const useGetPatientVideoVisitLink = () => {
  const [externalLinkLoading, setExternalLinkLoading] = useState(false);
  const { currentUser } = useAuth();

  const getPatientVideoVisitLink = async (guid?: string) => {
    setExternalLinkLoading(true);
    const token = await currentUser?.getIdToken();

    if (!token || !guid) {
      throw new Error("Token or consult Id missing");
    }
    const { url } = await generatePatientUIURLForVisit(token, guid, {
      returnUrl: SURVEY_URL,
      element: "video_visit",
    });

    return url;
  };

  return {
    externalLinkLoading,
    setExternalLinkLoading,
    getPatientVideoVisitLink,
  };
};
