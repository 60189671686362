import { useMemo } from "react";
import { useAuth } from "../context/AuthContext";
import { TreatmentPlanState } from "../typings/enums";
import { useSideEffectConsultQuery } from "./react-query/useConsultQuery";
import { useLabsRequiredQuery } from "./react-query/useLabsRequiredQuery";
import { useLatestPrescriptionRealtime } from "./react-query/useLatestPrescriptionRealtime";
import { useMessagesRealtime } from "./react-query/useMessagesRealTime";
import {
  useLatestRefillSessionByUser,
  useRefillSession,
} from "./react-query/useRefillSession";
import { MessageRecipient } from "../utils/messageUtils";

type ReturnType = {
  data?: TreatmentPlanState;
  isLoading: boolean;
};

export const useTreatmentPlanState = (): ReturnType => {
  const { currentUser } = useAuth();
  const { data: sideEffectConsult, isLoading: sideEffectConsultLoading } =
    useSideEffectConsultQuery(currentUser?.uid || "");
  const { data: labsRequired, isLoading: labsRequiredLoading } =
    useLabsRequiredQuery(currentUser?.uid || "");
  const {
    data: latestIncompleteRefillSession,
    isLoading: latestIncompleteRefillSessionLoading,
  } = useLatestRefillSessionByUser(currentUser?.uid || "", false);
  const {
    data: latestCompleteRefillSession,
    isLoading: latestCompleteRefillSessionLoading,
  } = useLatestRefillSessionByUser(currentUser?.uid || "", true);
  const prescriptionData = useLatestPrescriptionRealtime();
  const {
    data: refillSessionOnPrescription,
    isLoading: refillSessionOnPrescriptionLoading,
  } = useRefillSession(
    prescriptionData?.refillSessionId ?? "",
    currentUser?.uid ?? ""
  );
  const { data: messages, isLoading: messagesLoading } = useMessagesRealtime(
    currentUser?.uid ?? ""
  );

  const lastMessageFromClinician = useMemo(() => {
    if (!messages || messages.length === 0) return false;

    const refillSubmissionDate =
      latestCompleteRefillSession?.updatedAt?.toDate();
    const sideEffectConsultSubmissionDate = sideEffectConsult?.createdAt
      ? new Date(sideEffectConsult.createdAt)
      : null;

    const refillSubmittedAfterMessage =
      refillSubmissionDate &&
      refillSubmissionDate.getTime() > new Date(messages[0].sentAt).getTime();

    const sideEffectConsultSubmittedAfterMessage =
      sideEffectConsultSubmissionDate &&
      sideEffectConsultSubmissionDate.getTime() >
        new Date(messages[0].sentAt).getTime();

    const waitingForResponse =
      refillSubmittedAfterMessage || sideEffectConsultSubmittedAfterMessage;

    // 0 element of the array is the last message (sorted by sentAt desc)
    return (
      messages[0].recipient === MessageRecipient.Patient && !waitingForResponse
    );
  }, [
    messages,
    latestCompleteRefillSession?.updatedAt,
    sideEffectConsult?.createdAt,
  ]);

  const isLoading =
    sideEffectConsultLoading ||
    labsRequiredLoading ||
    latestIncompleteRefillSessionLoading ||
    latestCompleteRefillSessionLoading ||
    refillSessionOnPrescriptionLoading ||
    messagesLoading;

  if (isLoading) return { isLoading };

  // Default to normal treatment plan state
  let state: TreatmentPlanState = TreatmentPlanState.TreatmentPlan;

  if (labsRequired) {
    state = TreatmentPlanState.LabsRequired;
  } else if (lastMessageFromClinician) {
    state = TreatmentPlanState.MessageFromProvider;
  } else if (
    // If there is a refill session and it is not completed
    prescriptionData?.refillSessionId &&
    !Object.keys(refillSessionOnPrescription?.answers ?? {})?.length &&
    new Date(prescriptionData?.refillDate as string).getTime() <
      new Date().getTime()
  ) {
    state = TreatmentPlanState.RefillCheckIn;
  } else if (
    latestIncompleteRefillSession &&
    prescriptionData?.refillDate &&
    new Date(latestIncompleteRefillSession.createdAt).getTime() >
      new Date(prescriptionData?.refillDate).getTime()
  ) {
    state = TreatmentPlanState.ContinueCheckIn;
  } else if (
    latestCompleteRefillSession &&
    prescriptionData?.refillDate &&
    new Date(latestCompleteRefillSession.createdAt).getTime() >
      new Date(prescriptionData?.refillDate).getTime()
  ) {
    state = TreatmentPlanState.CheckInReview;
  } else if (
    sideEffectConsult &&
    ["completed", "rejected", "referred_out"].indexOf(
      sideEffectConsult.visitStatus
    ) === -1
  ) {
    state = TreatmentPlanState.SideEffects;
  }

  return { data: state, isLoading };
};
