import { getPlatforms } from "@ionic/react";

export const isEqual = <T>(obj1: T, obj2: T): boolean => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  )
    return false;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (!keys2.includes(key)) return false;

    const property1 = obj1[key as keyof T];
    const property2 = obj2[key as keyof T];

    if (!isEqual(property1, property2)) return false;
  }
  return true;
};

export const extractPathFromUrl = (urlString: string): string | null => {
  try {
    // Guard against empty strings
    if (!urlString?.trim()) {
      return null;
    }

    // Ensure URL has protocol
    if (!urlString.startsWith("http://") && !urlString.startsWith("https://")) {
      urlString = `https://${urlString}`;
    }

    const url = new URL(urlString);
    // Guard against empty pathname
    return url.pathname === "/" ? null : url.pathname;
  } catch (error) {
    console.warn("Error parsing URL:", urlString, error);
    // Try a simpler path extraction if URL parsing fails
    try {
      const pathMatch = urlString.match(/\.app(.+)$/);
      if (pathMatch?.[1]) {
        return pathMatch[1];
      }
    } catch (e) {
      console.warn("Fallback path extraction failed:", e);
    }
    return null;
  }
};

export const LEGACY_FH_APP_LINKS = {
  default: "https://apps.apple.com/us/app/futurhealth/id6473443810",
  ios: "com.futurhealth.futurhealth://",
  android: "futurhealthapp",
};

export enum osEnum {
  android = "Android",
  ios = "iOS",
}

export const detectDevice = () => {
  const platforms = getPlatforms();

  if (platforms.includes("mobile")) {
    if (platforms.includes("android"))
      return { device: "mobile", os: osEnum.android };
    if (platforms.includes("ios")) return { device: "mobile", os: "iOS" };
    return { device: "mobile", os: osEnum.ios };
  }

  return { device: "desktop", os: "unknown" };
};
