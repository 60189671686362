export enum Medication {
  semaglutide = "semaglutide",
  metformin = "metformin",
  tirzepatide = "tirzepatide",
  ozempic = "ozempic",
  zepbound = "zepbound",
}

export enum ConsultType {
  scheduled_video_visit = "scheduled_video_visit",
  async_messaging = "async_messaging",
  followup_video_visit = "followup_video_visit",
  chart_review = "chart_review",
  async_lab_order = "async_lab_order",
  chart_addendum = "chart_addendum",
  prior_authorization = "prior_authorization",
}

export enum ConsultReason {
  initial = "initial",
  follow_up = "follow_up",
  side_effect = "side_effect",
}

export enum VisitType {
  refill = "refill",
  sideEffect = "sideEffect",
  followUp = "followUp",
  initial = "initial",
  chartAddendum = "chart_addendum",
  other = "other",
}

export enum Miscs {
  mealPlanSetup = "mealPlanSetup",
  firstCheckin = "firstCheckin", // "initialCheckin",
}

export enum MealLabels {
  breakfast = "Breakfast",
  lunch = "Lunch",
  dinner = "Dinner",
  snack = "Snack",
}

export enum CardBrands {
  amex = "amex",
  discover = "discover",
  mastercard = "mastercard",
  visa = "visa",
}

export enum TreatmentPlanState {
  TreatmentPlan = "treatmentPlan",
  RefillCheckIn = "refillCheckIn",
  ContinueCheckIn = "continueCheckIn",
  CheckInReview = "checkInReview",
  SideEffects = "sideEffects",
  MessageFromProvider = "messageFromProvider",
  AppointmentRequired = "appointmentRequired",
  LabsRequired = "labsRequired",
  AdditionalSetupRequired = "additionalSetupRequired",
  CareUpdate = "careUpdate",
}

// TODO - clean up when semaglutide ff is removed
export enum MembershipPrice {
  monthly = 129,
  oneTime = 99,
  nutritionProgram = 39,
  programOriginalPrice = 645,
  nutritionProgramOriginalPrice = 78,
  discount = 30,
}

export enum MembershipPriceSB {
  monthly = 299,
  oneTime = 229, // monthly - discount
  nutritionProgram = 39,
  nutritionProgramOriginalPrice = 78,
  programPrice = 207.67, // monthly - pharmacyCoverage
  discount = 70,
  pharmacyCoverage = 91.33,
}

export enum MembershipMonthlyPrice {
  monthly = 299,
  monthlyOriginalPrice = 375,
  discount = 75,
}

export enum AffirmPrice {
  asLowAsPrice = 166.5,
  asLowAsPrice3Months = 111.38,
  brandedMedsMonthly = 999,
  brandedMeds6Months = 999 * 6,
  brandedMeds3Months = 999 * 3,
  semaSixMonths = MembershipPriceSB.oneTime * 6,
}

export enum CheckoutRoutes {
  Approval = "/approval",
  Results = "/results",
  Address = "/address",
  Payment = "/payment",
  AffirmPayment = "/affirm-payment",
  Account = "/account",
}

export enum AffirmTransactionStatus {
  Succeeded = "succeeded",
  RequiresAction = "requires_action",
  Failed = "requires_payment_method",
}
