import axios, { AxiosResponse } from "axios";
import {
  Buyer,
  EmbedTokenRequest,
  EmbedTokenResponse,
  GetTransactionsResponse,
  GoogleSessionResponse,
  LineItem,
  ProductData,
  ProductDataResponse,
  SecureCheckoutResponse,
  SecureCheckoutResponseWithMedicationName,
  ShippingDetails,
  TransactionData,
  TransactionRequest,
  TransactionResponse,
  UserDataResponse,
} from "../typings/paymentsServiceTypes";
import { User } from "firebase/auth";

const getPaymentsBaseUrl = (): string => {
  return import.meta.env.VITE_PAYMENTS_API_URL;
};

const axiosInstance = axios.create({
  baseURL: getPaymentsBaseUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
});

/**
 * Gets the embedded token for Gr4vy form.
 * @param productName - The name of the procut.
 * @param futurhealthPlusId - The ID of the FH user.
 * @param buyer - The buyer information.
 * @param currentUser - The current authenticated user.
 * @param shippingDetails - The shipping details.
 * @returns The embedded token response.
 */
async function getGr4vyEmbedToken(
  productName: string,
  futurhealthPlusId: string,
  buyer: Buyer,
  user: User | null,
  shippingDetails: ShippingDetails
): Promise<{ res: EmbedTokenResponse; medicationName: string }> {
  try {
    if (!user) throw new Error("User is not authenticated");

    const token = await user.getIdToken();

    const ret = await getProductData({ productName, user });

    const [
      {
        id,
        edges: { line_items: lineItemList },
        medication_name: medicationName,
      },
    ] = ret.data;

    // Filter and sort line item IDs
    const lineItemIDs = lineItemList
      .filter((item: LineItem) => item.billing_cycle === "initial")
      .sort((item1, item2) => item1.display_order - item2.display_order)
      .map((item: LineItem) => item.id as string);

    const requestData: EmbedTokenRequest = {
      buyer,
      shipping_details: shippingDetails,
      line_item_ids: lineItemIDs,
      futurhealth_plus_id: futurhealthPlusId,
      product_id: id,
    };

    const response: AxiosResponse<EmbedTokenResponse> =
      await axiosInstance.post("/v1/gr4vy/embed", requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return { res: response.data, medicationName };
  } catch (error) {
    console.error("Error getting embed token:", error);
    throw error;
  }
}

interface GetUserDataParams {
  futurhealthPlusId?: string;
  user: User | null;
}

/**
 * Gets user data from the payments service.
 * @param futurhealthPlusId - The ID of the FH user.
 * @param user - The current authenticated user.
 * @returns The user data response.
 */
const getUserData = async ({
  futurhealthPlusId,
  user,
}: GetUserDataParams): Promise<UserDataResponse> => {
  try {
    if (!user) throw new Error("User is not authenticated");

    const token = await user.getIdToken();

    const response: AxiosResponse<UserDataResponse> = await axiosInstance.get(
      "/v1/users",
      {
        params: {
          futurhealth_plus_id: futurhealthPlusId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error getting user data:", error);
    throw error;
  }
};

interface GetProductDataParams {
  productName?: string;
  productId?: string;
  user: User | null;
}

/**
 * Gets all product data and filters by product name if passed as a parameter.
 * @param productName - The name of the product to filter by (optional).
 * @param productId - The id of the product to filter by (optional).
 * @returns All data or filtered data are first in the returned array.
 */
const getProductData = async ({
  productName,
  productId,
  user,
}: GetProductDataParams): Promise<ProductDataResponse> => {
  try {
    if (!user) throw new Error("User is not authenticated");

    const token = await user.getIdToken();

    // Determine query parameter based on availability
    const queryParam = productId
      ? { id: productId }
      : productName
        ? { name: productName }
        : null;

    const response: AxiosResponse<ProductDataResponse> =
      await axiosInstance.get("/v1/products", {
        params: queryParam,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return response.data;
  } catch (error) {
    console.error("Error getting product data:", error);
    throw error;
  }
};

interface GetProductByIdParams {
  productId?: string;
  user: User | null;
}

/**
 * Gets information about a single product by ID.
 * @param productId - The ID of the product.
 * @returns The product information.
 */
const getProductById = async ({
  productId,
  user,
}: GetProductByIdParams): Promise<ProductData> => {
  try {
    if (!user) throw new Error("User is not authenticated");

    if (!productId) throw new Error("productId is required");

    const token = await user.getIdToken();

    const response: AxiosResponse<ProductData> = await axiosInstance.get(
      `/v1/products/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error getting product data:", error);
    throw error;
  }
};

/**
 * Gets the embedded token for Gr4vy form.
 * @param productName - The name of the procut.
 * @param futurhealthPlusId - The ID of the FH user.
 * @param buyer - The buyer information.
 * @param currentUser - The current authenticated user.
 * @param shippingDetails - The shipping details.
 * @param hasDiscount - if true then discount is applied for the selected product.
 * @returns The embedded token response.
 */
async function getSecureFieldCheckout(
  productName: string,
  futurhealthPlusId: string,
  buyer: Buyer,
  user: User | null,
  shippingDetails: ShippingDetails,
  hasDiscount: boolean = false
): Promise<SecureCheckoutResponseWithMedicationName> {
  try {
    if (!user) throw new Error("User is not authenticated");

    const token = await user.getIdToken();

    const ret = await getProductData({ productName, user });

    const [
      {
        id,
        edges: { line_items: lineItemList, discounts },
        medication_name: medicationName,
      },
    ] = ret.data;

    // Filter and sort line item IDs
    const lineItemIDs = lineItemList
      .filter((item: LineItem) => item.billing_cycle === "initial")
      .sort((item1, item2) => item1.display_order - item2.display_order)
      .map((item: LineItem) => item.id as string);

    const discountId = hasDiscount
      ? discounts?.find((discount) => discount?.active === true)?.id
      : "";
    const requestData: EmbedTokenRequest = {
      buyer,
      shipping_details: shippingDetails,
      line_item_ids: lineItemIDs,
      futurhealth_plus_id: futurhealthPlusId,
      product_id: id,
      discount_id: discountId,
    };

    const response: AxiosResponse<SecureCheckoutResponse> =
      await axiosInstance.post(
        "/v1/gr4vy/secure-fields-checkout",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

    return { res: response.data, medicationName };
  } catch (error) {
    console.error("Error getting secure field checkout:", error);
    throw error;
  }
}

async function createTransaction(
  user: User | null,
  transaction: TransactionRequest
): Promise<TransactionResponse> {
  try {
    if (!user) throw new Error("User is not authenticated");

    const authToken = await user.getIdToken();

    const response: AxiosResponse<TransactionResponse> =
      await axiosInstance.post("/v1/gr4vy/transactions", transaction, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

    return response.data;
  } catch (error) {
    // return error message from response if available, otherwise return generic error message
    const errorResponse =
      axios.isAxiosError(error) && error.response
        ? error.response.data.error
        : "Unknown error";
    console.error("Error creating transaction:", error);
    throw errorResponse;
  }
}

/**
 * Get transactions for a user
 * @param user The current authenticated user
 * @param medicationOnly Flag to filter transactions for medication only
 * @returns List of transactions
 */
async function getTransactions(
  user: User | null,
  medicationOnly?: boolean
): Promise<TransactionData[]> {
  try {
    if (!user) throw new Error("User is not authenticated");

    const authToken = await user.getIdToken();

    // Get user data to retrieve gr4vyId
    const userData = await getUserData({ user });
    const gr4vyId = userData.data[0]?.id;

    if (!gr4vyId) throw new Error("Gr4vy ID not found for the user");

    const response: AxiosResponse<GetTransactionsResponse> =
      await axiosInstance.get(`/v1/transactions`, {
        params: {
          user_id: gr4vyId,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

    let transactions = response.data.data;

    if (medicationOnly) {
      transactions = transactions.filter(
        (transaction) => transaction.metadata.type === "medication"
      );
    }

    return transactions;
  } catch (error) {
    console.error("Error getting transactions:", error);
    throw error;
  }
}

const getProducts = async (
  params: { [key: string]: string },
  token: string
): Promise<ProductDataResponse> => {
  const response: AxiosResponse<ProductDataResponse> = await axiosInstance.get(
    "/v1/products",
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

const initApplePaySession = async (validationURL: string, user: User) => {
  const token = await user.getIdToken();
  const response: AxiosResponse = await axiosInstance.post(
    "/v1/gr4vy/digital-wallets/apple/session",
    {
      validation_url: validationURL,
      domain_name: "plus.fh.co",
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const initGooglePaySession = async (
  user: User
): Promise<GoogleSessionResponse> => {
  const token = await user.getIdToken();
  const response: AxiosResponse = await axiosInstance.post(
    "/v1/gr4vy/digital-wallets/google/session",
    {
      origin_domain: "plus.fh.co",
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export {
  createTransaction,
  getTransactions,
  getGr4vyEmbedToken,
  getUserData,
  getProductData,
  getProductById,
  getSecureFieldCheckout,
  getProducts,
  initApplePaySession,
  initGooglePaySession,
};
