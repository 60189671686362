import { useRef, useState, useCallback, useMemo, useEffect } from "react";
import { doc, DocumentData, onSnapshot } from "firebase/firestore";
import { db } from "../../conf/firebaseConfig";
import { useQueryClient } from "@tanstack/react-query";
import { useLatestPrescriptionQuery } from "./usePrescriptionQuery";
import { Prescription } from "../../typings";
import { isEqual } from "../../utils/common";

export const useLatestPrescriptionRealtime = (
  overridePrescriptionId?: string
) => {
  const { data } = useLatestPrescriptionQuery();
  const prescriptionId = overridePrescriptionId || data?.prescriptionId;
  const [latestPrescription, setLatestPrescription] = useState<
    Prescription | undefined
  >();
  const [error, setError] = useState<Error | null>(null);

  const queryClient = useQueryClient();
  const unsubscribeRef = useRef<(() => void) | undefined>();
  const prevPrescriptionIdRef = useRef<string | undefined>();

  const queryKey = useMemo(
    () => ["latest prescription realtime", { prescriptionId }],
    [prescriptionId]
  );

  const updatePrescriptionData = useCallback(
    (newData: Prescription | undefined) => {
      queryClient.setQueryData(queryKey, newData);
      setLatestPrescription((prev) =>
        isEqual(prev, newData) ? prev : newData
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      queryClient,
      queryKey,
      latestPrescription, // this unused dep is included intentionally
    ]
  );

  const handleSnapshot = useCallback(
    (doc: DocumentData) => {
      const newData = doc.data() as Prescription | undefined;
      updatePrescriptionData(newData);
    },
    [updatePrescriptionData]
  );

  const manageListener = useCallback(() => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
      unsubscribeRef.current = undefined;
    }

    if (prescriptionId) {
      unsubscribeRef.current = onSnapshot(
        doc(db, "steadymd.prescriptions", prescriptionId),
        handleSnapshot,
        (error) => {
          console.error("Error in Firestore listener:", error);
          setError(error);
        }
      );
    }
  }, [prescriptionId, handleSnapshot]);

  // Use effect to manage the listener based on prescriptionId changes
  useEffect(() => {
    if (prescriptionId !== prevPrescriptionIdRef.current) {
      manageListener(); // Setup or update listener when prescriptionId changes
      prevPrescriptionIdRef.current = prescriptionId;
    }
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = undefined;
      }
    };
  }, [prescriptionId, manageListener]);

  if (!latestPrescription) return null;
  return { ...latestPrescription, error };
};
