import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface PaymentStatusResult {
  status: string | null;
  error: string | null;
}

const checkPaymentStatus = async (
  clientSecret: string
): Promise<PaymentStatusResult> => {
  const stripe = await stripePromise;
  if (!stripe) {
    return { status: null, error: "Stripe is not initialized." };
  }

  try {
    const { paymentIntent, error } =
      await stripe.retrievePaymentIntent(clientSecret);
    if (error) {
      return { status: null, error: error.message ?? "Unknown error" };
    }

    if (paymentIntent) {
      return { status: paymentIntent.status, error: null };
    } else {
      return { status: null, error: "PaymentIntent not found." };
    }
  } catch (err) {
    return { status: null, error: (err as Error).message };
  }
};

export { checkPaymentStatus };
