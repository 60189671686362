import { Flex } from "@mantine/core";
import { colors } from "../../theme/colors";

export const ModalDivider = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Flex
      onClick={onClick}
      style={{
        borderWidth: "100px",
        cursor: "pointer",
      }}
      bg={colors.lightGray}
      h={4}
      w={56}
      mb={10}
    />
  );
};
