import { faCircleInfo as faCircleInfoRegular } from "@fortawesome/pro-regular-svg-icons";
import {
  faBox,
  faCircleInfo,
  faExclamation,
  faExclamationCircle,
  faFlask,
  faLoader,
  faLocationDot,
  faPersonCircleQuestion,
  faTruckFast,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavContextState } from "@ionic/react";
import {
  Alert,
  Button,
  ButtonVariant,
  Card,
  Flex,
  Stack,
  Text,
} from "@mantine/core";
import dayjs from "dayjs";
import { openZendesk } from "../services/zendeskService";
import { colors } from "../theme/colors";
import { MedicationOrderStatusParams, Prescription } from "../typings";
import { Medication } from "../typings/enums";
import inAppBrowser from "./browser";

export enum MedicationOrderStatus {
  Pending = "pending",
  Processing = "processing",
  Shipping = "shipping",
  Delivered = "delivered",
  OnHoldForLabs = "onHoldForLabs",
  OnHoldForPayment = "onHoldForPayment",
  Refunded = "refunded",
  Canceled = "canceled",
  RequiresConfirmation = "requiresConfirmation",
  ConfirmedDelivered = "confirmedDelivered",
}

export function determineMedicationOrderStatus({
  medicationOrderPayment,
  prescription,
  bundled = false,
}: MedicationOrderStatusParams): MedicationOrderStatus {
  if (!prescription) {
    return MedicationOrderStatus.OnHoldForLabs;
  }

  const paymentStatus = bundled ? "succeeded" : medicationOrderPayment?.status;
  if (!medicationOrderPayment && !bundled)
    return MedicationOrderStatus.OnHoldForPayment;
  if (paymentStatus === "refunded") return MedicationOrderStatus.Refunded;
  if (paymentStatus === "canceled") return MedicationOrderStatus.Canceled;
  if (paymentStatus === "failed") return MedicationOrderStatus.OnHoldForPayment;
  if (isPaymentPending(paymentStatus))
    return MedicationOrderStatus.OnHoldForPayment;
  if (paymentStatus === "succeeded") {
    const status = determineSuccessfulPaymentStatus(prescription);
    return checkForRequiredConfirmation(status, prescription);
  }

  return MedicationOrderStatus.Pending;
}

function determineSuccessfulPaymentStatus(
  prescription: Prescription
): MedicationOrderStatus {
  if (prescription.shipDate) return MedicationOrderStatus.Shipping;
  if (prescription.fillDate) return MedicationOrderStatus.Processing;
  if (
    prescription.holdReason ||
    (prescription.createdDate && !prescription.fillDate)
  ) {
    return MedicationOrderStatus.Pending;
  }
  return MedicationOrderStatus.Pending;
}

function isPaymentPending(paymentStatus: string | undefined): boolean {
  const pendingStatuses = new Set([
    "requires_payment_method",
    "requires_confirmation",
    "requires_action",
    "processing",
    "pending",
    "unknown",
  ]);
  return pendingStatuses.has(paymentStatus ?? "");
}

function checkForRequiredConfirmation(
  status: MedicationOrderStatus,
  prescription: Prescription
): MedicationOrderStatus {
  const ONE_WEEK_IN_DAYS = 7;
  const isProcessingOrShipping =
    status === MedicationOrderStatus.Processing ||
    status === MedicationOrderStatus.Shipping;

  if (isProcessingOrShipping && prescription.createdDate) {
    const oneWeekAgo = dayjs().subtract(ONE_WEEK_IN_DAYS, "day");
    if (dayjs(prescription.createdDate).isBefore(oneWeekAgo)) {
      return MedicationOrderStatus.RequiresConfirmation;
    }
  }
  return status;
}

const highlightWords = (word: string, red = false) =>
  red ? (
    <span style={{ color: "var(--red)" }}>{word}</span>
  ) : (
    <span style={{ color: "var(--blurple)" }}>{word}</span>
  );

export const getCardHeader = (status: MedicationOrderStatus) => {
  switch (status) {
    case MedicationOrderStatus.Pending:
      return <span>Your medication is {highlightWords("pending")}</span>;
    case MedicationOrderStatus.Processing:
      return (
        <span>Your medication is being {highlightWords("processed")}</span>
      );
    case MedicationOrderStatus.Shipping:
      return <span>Your medication is being {highlightWords("shipped")}</span>;
    case MedicationOrderStatus.Delivered:
    case MedicationOrderStatus.ConfirmedDelivered:
      return (
        <span>Your medication has been {highlightWords("delivered")}</span>
      );
    case MedicationOrderStatus.OnHoldForLabs:
    case MedicationOrderStatus.OnHoldForPayment:
      return (
        <span>Your medication order is {highlightWords("on hold", true)}</span>
      );
    case MedicationOrderStatus.Refunded:
      return (
        <span>
          Your medication order has been {highlightWords("refunded", true)}
        </span>
      );
    case MedicationOrderStatus.Canceled:
      return (
        <span>
          Your medication order has been {highlightWords("canceled", true)}
        </span>
      );
    case MedicationOrderStatus.RequiresConfirmation:
      return (
        <span>
          Your medication order requires {highlightWords("confirmation")}
        </span>
      );
    default:
      return "";
  }
};

const createAlert = (text: string, icon: IconDefinition) => {
  return (
    <Alert
      variant="light"
      color="var(--red)"
      styles={() => ({
        root: {
          borderRadius: "8px",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
        },
        wrapper: {
          alignItems: "center",
        },
        body: {
          flex: 1,
        },
      })}
    >
      <Flex align="center" gap="md">
        <FontAwesomeIcon
          icon={icon}
          size="lg"
          style={{ flexShrink: 0, color: "var(--red)" }}
        />
        <Text c="var(--red)">{text}</Text>
      </Flex>
    </Alert>
  );
};

const createDescription = (text: JSX.Element, icon: IconDefinition) => {
  return (
    <Flex justify="center" gap="sm" align="center">
      <FontAwesomeIcon icon={icon} size="lg" />
      {text}
    </Flex>
  );
};

export const getCardDescription = (status: MedicationOrderStatus) => {
  switch (status) {
    case MedicationOrderStatus.Pending:
      return createDescription(
        <Text>
          Order confirmed. Pharmacy processing may take{" "}
          {highlightWords("24-48 hours.")}
        </Text>,
        faLoader
      );
    case MedicationOrderStatus.Processing:
      return createDescription(
        <Text>
          Order created and is processing. Usually ships in{" "}
          {highlightWords("3-5 business days.")}
        </Text>,
        faBox
      );
    case MedicationOrderStatus.Shipping:
      return createDescription(
        <Text>
          Order was fulfilled and is shipping. Usually arrives in{" "}
          {highlightWords("1-2 business days.")}
        </Text>,
        faTruckFast
      );

    case MedicationOrderStatus.Delivered:
      return createDescription(
        <Text>
          Please confirm your delivery to update your program roadmap.
        </Text>,
        faLocationDot
      );
    case MedicationOrderStatus.OnHoldForLabs:
      return createAlert(
        "Your medication is on hold until the required lab work is completed.",
        faFlask
      );
    case MedicationOrderStatus.OnHoldForPayment:
      return createAlert(
        "Your medication is on hold until a payment is successfully processed",
        faExclamation
      );
    case MedicationOrderStatus.Refunded:
      return createDescription(
        <Text>
          Order refunded. Refund processing may take
          {highlightWords(" 7-10 business days.")}
        </Text>,
        faCircleInfo
      );

    case MedicationOrderStatus.Canceled:
      return createAlert(
        "Your prescription medication order has been canceled.",
        faExclamation
      );
    case MedicationOrderStatus.RequiresConfirmation:
      return createAlert(
        "We are unable to verify the delivery status of your order.",
        faExclamation
      );
  }
};

export const getProgress = (status: MedicationOrderStatus) => {
  switch (status) {
    case MedicationOrderStatus.Processing:
      return 1;
    case MedicationOrderStatus.Shipping:
      return 2;
    case MedicationOrderStatus.Delivered:
    case MedicationOrderStatus.ConfirmedDelivered:
      return 3;
    default:
      return 0;
  }
};

export const getOrderStatus = (
  status: MedicationOrderStatus,
  orderNumber?: string | undefined
) => {
  if (
    status === MedicationOrderStatus.OnHoldForLabs ||
    status === MedicationOrderStatus.OnHoldForPayment
  ) {
    return <Text c={colors.darkGray}>Action Required</Text>;
  }
  if (orderNumber) {
    return <Text c={colors.darkGray}>Order #{orderNumber}</Text>;
  }

  return <Text c={colors.disabledGray}>Order confirmed</Text>;
};

const renderButton = (
  isMobile: boolean,
  text: string,
  variant: ButtonVariant,
  onClick: () => void
) => (
  // TODO: add onClick handler to buttons once defined
  <Button
    variant={variant}
    onClick={() => onClick()}
    color="var(--blurple)"
    w={isMobile ? "100%" : "50%"}
    size="lg"
    radius="50px"
  >
    {text}
  </Button>
);

export const getActionButtons = ({
  status,
  isMobile,
  setShowDetails,
  navContext,
  hideShowDetailsBtn,
  handleOpenDeliveryModal,
  prescription,
}: {
  status: MedicationOrderStatus;
  isMobile: boolean;
  setShowDetails: () => void;
  navContext: NavContextState;
  hideShowDetailsBtn?: boolean;
  handleOpenDeliveryModal?: (status: MedicationOrderStatus) => void;
  prescription: Prescription | undefined;
}) => {
  switch (status) {
    case MedicationOrderStatus.Pending:
    case MedicationOrderStatus.Processing:
    case MedicationOrderStatus.Refunded:
    case MedicationOrderStatus.Canceled:
      if (!hideShowDetailsBtn) {
        return renderButton(isMobile, "View Details", "light", setShowDetails);
      }
      return null;
    case MedicationOrderStatus.Shipping: {
      let trackOrderBtn: JSX.Element | null = null;

      if (prescription && prescription.tracking && prescription.carrier) {
        const trackingUrl =
          prescription.carrier.toLowerCase() === "fedex"
            ? `https://www.fedex.com/fedextrack/?tracknumbers=${prescription.tracking}`
            : `https://www.ups.com/track?tracknum=${prescription.tracking}`;
        trackOrderBtn = renderButton(isMobile, "Track Order", "filled", () =>
          inAppBrowser.open(trackingUrl)
        );
      }
      return (
        <>
          {trackOrderBtn}
          {!hideShowDetailsBtn &&
            renderButton(isMobile, "View Details", "light", setShowDetails)}
        </>
      );
    }
    case MedicationOrderStatus.Delivered:
      return (
        <>
          {renderButton(isMobile, "Confirm Delivery", "filled", () =>
            handleOpenDeliveryModal
              ? handleOpenDeliveryModal(MedicationOrderStatus.Delivered)
              : {}
          )}
          {!hideShowDetailsBtn &&
            renderButton(isMobile, "View Details", "light", setShowDetails)}
        </>
      );
    case MedicationOrderStatus.ConfirmedDelivered:
      return (
        <>
          {renderButton(isMobile, "Report an Issue", "filled", () =>
            openZendesk()
          )}
          {!hideShowDetailsBtn &&
            renderButton(isMobile, "View Details", "light", setShowDetails)}
        </>
      );
    case MedicationOrderStatus.OnHoldForLabs:
      return (
        <>
          {renderButton(isMobile, "Start Lab Work", "filled", () =>
            navContext.navigate("/start-lab-work")
          )}
          {!hideShowDetailsBtn &&
            renderButton(isMobile, "View Details", "light", setShowDetails)}
        </>
      );
    case MedicationOrderStatus.OnHoldForPayment:
      return (
        <>
          {renderButton(isMobile, "Review Payment Method", "filled", () =>
            navContext.navigate("/payment-methods")
          )}
          {!hideShowDetailsBtn &&
            renderButton(isMobile, "View Details", "light", setShowDetails)}
        </>
      );
    case MedicationOrderStatus.RequiresConfirmation:
      return (
        <>
          {renderButton(isMobile, "Confirm Delivery", "filled", () =>
            handleOpenDeliveryModal ? handleOpenDeliveryModal(status) : {}
          )}
          {!hideShowDetailsBtn &&
            renderButton(isMobile, "View Details", "light", setShowDetails)}
        </>
      );
  }
};

const renderDetailsHeader = (icon: IconDefinition, text: string) => {
  return (
    <Text ta="start" fw={600} fz="16px">
      <FontAwesomeIcon icon={icon} size="lg" style={{ marginRight: "15px" }} />
      {text}
    </Text>
  );
};

const detailsHeader = (status: MedicationOrderStatus) => {
  switch (status) {
    case MedicationOrderStatus.OnHoldForLabs:
    case MedicationOrderStatus.OnHoldForPayment:
      return renderDetailsHeader(faExclamationCircle, "Order On Hold");
    case MedicationOrderStatus.Pending:
      return renderDetailsHeader(faLoader, "Order Pending");
    case MedicationOrderStatus.Shipping:
      return renderDetailsHeader(faTruckFast, "Order Shipping");
    case MedicationOrderStatus.Delivered:
      return renderDetailsHeader(faLocationDot, "Order Delivered");
    case MedicationOrderStatus.RequiresConfirmation:
      return renderDetailsHeader(faExclamation, "Order Requires Confirmation");
    case MedicationOrderStatus.Refunded:
      return renderDetailsHeader(faExclamationCircle, "Order Refunded");
    case MedicationOrderStatus.Canceled:
      return renderDetailsHeader(faExclamationCircle, "Order Canceled");
    default:
    case MedicationOrderStatus.Processing:
      return renderDetailsHeader(faBox, "Order Processing");
  }
};

const renderDetailsText = (text: string) => {
  return (
    <Text fz="14px" fw={400} lh="22px">
      {text}
    </Text>
  );
};

const detailsText = (status: MedicationOrderStatus) => {
  switch (status) {
    case MedicationOrderStatus.OnHoldForLabs:
      return renderDetailsText(
        "Your clinician needs some lab work before moving forward. This helps make sure treatments are safe and right for you. Your order is on hold until we get your lab results, so be sure to complete it as soon as possible"
      );
    case MedicationOrderStatus.OnHoldForPayment:
      return renderDetailsText(
        "Your medication is ready to be processed, but we need you to update your payment method to avoid delays."
      );
    case MedicationOrderStatus.Pending:
      return renderDetailsText(
        "Good news! Your medication has been ordered and the pharmacy will start working on it soon. We'll update you when it's being processed."
      );
    case MedicationOrderStatus.Shipping:
      return renderDetailsText(
        "Great news! Your medication is on its way, and we'll send over tracking info soon. Keep an eye out for your medication!"
      );
    case MedicationOrderStatus.ConfirmedDelivered:
    case MedicationOrderStatus.Delivered:
      return renderDetailsText(
        "Your medication has been delivered! If there's any problem with your order please let us know as soon as possible. We're here to help you achieve your health goals!"
      );
    case MedicationOrderStatus.RequiresConfirmation:
      return renderDetailsText(
        "Did your medication arrive? Please let us know by either confirming or reporting an issue. This helps us make sure you have what you need to achieve your health goals."
      );
    case MedicationOrderStatus.Refunded:
      return renderDetailsText(
        "Your order has been refunded which means you won't receive your prescribed medication. If you think this is a mistake, please call our support team at (831) 900-4723."
      );
    case MedicationOrderStatus.Canceled:
      return renderDetailsText(
        "Your order was canceled which means you won't get your prescribed medication. If you think this was a mistake, please, call our support team at (831) 900-4723."
      );
    default:
    case MedicationOrderStatus.Processing:
      return renderDetailsText(
        "Your medication order is now being prepared. The pharmacy is working on it, and it should ship in 3-5 business days. We'll let you know when it's on the way."
      );
  }
};

export const getOrderDetails = (status: MedicationOrderStatus) => {
  return (
    <Stack>
      <Card shadow="none" padding="lg" radius="lg" withBorder>
        <Stack>
          {detailsHeader(status)}
          {detailsText(status)}
        </Stack>
      </Card>
      {
        <Text ta="start" fw={500} fz="28px">
          <FontAwesomeIcon
            icon={faCircleInfoRegular}
            size="sm"
            style={{ marginRight: "15px" }}
          />
          Policy Information
        </Text>
      }

      <Card shadow="none" padding="lg" radius="lg" withBorder>
        <Stack>
          {renderDetailsHeader(
            faPersonCircleQuestion,
            "Refund & Cancelation Policy"
          )}
          {renderDetailsText(
            "Need to cancel or refund your prescription order? Please be aware that orders can only be canceled and refunded before they have been processed by our partner pharmacy. Once a medication has been prescribed and processed, we are unable to cancel or refund the order. This policy ensures the safety and accuracy of our prescription process."
          )}
        </Stack>
      </Card>
    </Stack>
  );
};

export const SMDNDCToCommonName = (ndc: string) => {
  switch (ndc) {
    case "54288082601":
    case "54288083301":
    case "54288082701":
    case "54288083101":
      return Medication.semaglutide;
    case "54288083501":
    case "54288083201":
    case "54288083601":
      return Medication.tirzepatide;
    case "00002250680":
    case "00002249580":
    case "00002248480":
    case "00002247180":
    case "00002246080":
    case "00002245780":
      return Medication.zepbound;
    case "00169418113":
    case "00169413013":
    case "00169477212":
      return Medication.ozempic;
    default:
      return Medication.metformin;
  }
};

export const getMedicationOrderCta = (status: MedicationOrderStatus) => {
  switch (status) {
    case MedicationOrderStatus.OnHoldForLabs:
      return "Please complete your labs.";
    case MedicationOrderStatus.OnHoldForPayment:
      return "Please update your payment.";
    case MedicationOrderStatus.Pending:
      return "Your medication has been ordered!";
    case MedicationOrderStatus.Processing:
      return "The pharmacy is processing your Rx.";
    case MedicationOrderStatus.Shipping:
      return "Your meds are on the way!";
    case MedicationOrderStatus.Delivered:
    case MedicationOrderStatus.RequiresConfirmation:
      return "Did your meds arrive?";
    case MedicationOrderStatus.Refunded:
      return "We've processed your refund.";
    case MedicationOrderStatus.Canceled:
      return "We've canceled your order.";
    case MedicationOrderStatus.ConfirmedDelivered:
      return "Your meds were delivered.";
  }
};
