import { ReactNode, useCallback, useContext, useEffect } from "react";
import { App as CapApp } from "@capacitor/app";
import { extractPathFromUrl } from "../../utils/common";
import { NavContext } from "@ionic/react";
import { isPlatform } from "@ionic/core";
import { useQuery } from "../../hooks/useQuery";
import {
  getEpisodeByUser,
  getLastScheduleConsultByTypeAndStatus,
  getSurveySessionByUserId,
} from "../../services/firestoreService";
import { ConsultType } from "../../typings/enums";
import { ConsultVisitStatusEnum } from "@futurhealth/steadymd-api-client";
import { useMedicationOrderStatus } from "../../hooks/react-query/useMedicationOrderStatus";
import { useGetPatientVideoVisitLink } from "../../hooks/useGetPatientVideoVisitLink";
import { useReferredOutConsultQuery } from "../../hooks/react-query/useConsultQuery";
import { useLatestConsultRealtime } from "../../hooks/react-query/useLatestConsultRealtime";
import { useAuth } from "../../context/AuthContext";
import { useOnceOnPage } from "../../hooks/useCallOnceOnPage";
import { useLatestPrescriptionRealtime } from "../../hooks/react-query/useLatestPrescriptionRealtime";
import { useTreatmentPlanState } from "../../hooks/useTreatmentPlanState";
import { trackOnce } from "../../utils/tracking";
import { usePostHog } from "posthog-js/react";
import { PostHog } from "posthog-js";
import {
  APPOINTMENT_VIDEO_LINK_CREATION_FAILED,
  CREATE_PASSWORD_LINK_CREATION_FAILED,
  PAYMENT_LINK_CREATION_FAILED,
  SCHEDULE_APPOINTMENT_LINK_CREATION_FAILED,
} from "../../constants/posthogEvents";

enum destinations {
  paymentMethod = "paymentMethod",
  scheduleAppointment = "scheduleAppointment",
  rescheduleAppointment = "rescheduleAppointment",
  appointmentVideoLink = "appointmentVideoLink", //external link
  orderDetails = "orderDetails",
  treatmentPlan = "treatmentPlan",
  refillIntake = "refillIntake",
  afterVisitSummary = "afterVisitSummary",
  labWork = "labWork",
  messages = "messages",
  createPassword = "createPassword",
}

const redirectToMapping = {
  [destinations.paymentMethod]: "results",
  [destinations.scheduleAppointment]: "create-appointment/1",
  [destinations.rescheduleAppointment]: "reschedule-appointment/0",
  [destinations.appointmentVideoLink]: "appointment-video-link", //external link
  [destinations.orderDetails]: "medication-order",
  [destinations.treatmentPlan]: "treatment-plan",
  [destinations.refillIntake]: "refill-funnel",
  [destinations.afterVisitSummary]: "after-visit-summaries",
  [destinations.labWork]: "start-lab-work",
  [destinations.messages]: "messages",
  [destinations.createPassword]: "account",
};

const getScheduleAppointmentLink = async (
  userId: string,
  posthog: PostHog
): Promise<string> => {
  try {
    const episodeData = await getEpisodeByUser(userId);

    const consults = await getLastScheduleConsultByTypeAndStatus(
      userId,
      ConsultType.scheduled_video_visit,
      ConsultVisitStatusEnum.WaitingToSchedule
    );

    const lastWaitingConsultId = consults ? consults[0]?.guid : null;
    const episodeGuid = episodeData ? episodeData.episodeGuid : null;

    if (lastWaitingConsultId && episodeGuid) {
      return `/${redirectToMapping[destinations.scheduleAppointment]}/${lastWaitingConsultId}/${episodeGuid}`;
    }

    return "/";
  } catch (err) {
    void trackOnce(posthog, SCHEDULE_APPOINTMENT_LINK_CREATION_FAILED, {});
    console.log("getScheduleAppointmentLink failed with error: ", err);
    return "/";
  }
};

const getPaymentMethodLink = async (
  userId: string,
  route: string,
  posthog: PostHog
): Promise<string> => {
  try {
    const session = await getSurveySessionByUserId(userId);

    if (session) {
      return `${route}?surveySessionId=${session.id}`;
    }

    return "/";
  } catch (err) {
    void trackOnce(posthog, PAYMENT_LINK_CREATION_FAILED, {});
    console.log("getPaymentMethodLink failed with error: ", err);
    return "/";
  }
};

const getCreatePasswordLink = async (
  userId: string,
  route: string,
  posthog: PostHog
): Promise<string> => {
  try {
    const session = await getSurveySessionByUserId(userId);

    if (session) {
      return `${route}?surveySessionId=${session.id}`;
    }

    return "/";
  } catch (err) {
    void trackOnce(posthog, CREATE_PASSWORD_LINK_CREATION_FAILED, {});
    console.log("getCreatePasswordLink failed with error: ", err);
    return "/";
  }
};

export const AppUrlListener = ({ children }: { children: ReactNode }) => {
  const posthog = usePostHog();
  const navContext = useContext(NavContext);
  const { navigate } = navContext;
  const isDesktop = isPlatform("desktop");
  const query = useQuery();
  const {
    status: medicationOrderStatus,
    orderNumber,
    isLoading: isMedicationOrderStatusLoading,
  } = useMedicationOrderStatus();

  const { getPatientVideoVisitLink, setExternalLinkLoading } =
    useGetPatientVideoVisitLink();
  const { currentUser, isAuthenticated } = useAuth();
  const userId = currentUser?.uid;

  const { data: referredOutConsult, isLoading: referredOutConsultLoading } =
    useReferredOutConsultQuery(userId ?? "");
  const { data: consult, isLoading: consultLoading } = useLatestConsultRealtime(
    [ConsultType.scheduled_video_visit, ConsultType.followup_video_visit]
  );
  const prescription = useLatestPrescriptionRealtime();
  const { data: treatmentPlanState, isLoading: treatmentPlanStateLoading } =
    useTreatmentPlanState();

  const openLink = useOnceOnPage((url: string) => window.open(url));

  const navigateWithRootRoute = useCallback(
    (link: string) => {
      navigate("/");
      setTimeout(() => {
        navigate(link);
      }, 1);
    },
    [navigate]
  );

  const openPatientVideoVisitLink = useCallback(
    async (guid?: string) => {
      if (!guid) {
        navigate("/");
      }

      try {
        const url = await getPatientVideoVisitLink(guid);

        openLink(url);
      } catch (error) {
        navigate("/");
        void trackOnce(posthog, APPOINTMENT_VIDEO_LINK_CREATION_FAILED, {});
        console.error("openPatientVideoVisitLink failed with error: ", error);
      } finally {
        setExternalLinkLoading(false);
      }
    },
    [
      posthog,
      getPatientVideoVisitLink,
      navigate,
      openLink,
      setExternalLinkLoading,
    ]
  );

  const redirectBasedOnPassedParams = useCallback(async () => {
    const destination = (query.get("dest") as string) ?? undefined;
    const userId = (query.get("userid") as string) ?? undefined;

    const route =
      redirectToMapping[destination as keyof typeof redirectToMapping];

    let linkNavigateTo = "/";

    //paymentMethod, createPassword and scheduleAppointment are available for not authenticated users
    if (route === redirectToMapping[destinations.paymentMethod]) {
      linkNavigateTo = await getPaymentMethodLink(userId, route, posthog);

      navigateWithRootRoute(linkNavigateTo);
      return;
    } else if (route === redirectToMapping[destinations.scheduleAppointment]) {
      linkNavigateTo = await getScheduleAppointmentLink(userId, posthog);
      navigateWithRootRoute(linkNavigateTo);
    } else if (route === redirectToMapping[destinations.createPassword]) {
      linkNavigateTo = await getCreatePasswordLink(userId, route, posthog);

      navigateWithRootRoute(linkNavigateTo);
      return;
    }

    if (!isAuthenticated || currentUser?.uid !== userId) {
      navigate("/login");
    }

    switch (route) {
      case redirectToMapping[destinations.rescheduleAppointment]:
        linkNavigateTo = `/${redirectToMapping[destinations.rescheduleAppointment]}`;
        break;
      case redirectToMapping[destinations.orderDetails]:
        // Wait for medicationOrderStatus
        if (isMedicationOrderStatusLoading) {
          return;
        } else if (!orderNumber) {
          break;
        }

        linkNavigateTo = `/${redirectToMapping[destinations.orderDetails]}/${medicationOrderStatus}/${orderNumber}`;
        break;
      case redirectToMapping[destinations.appointmentVideoLink]:
        if (!userId || referredOutConsultLoading || consultLoading) {
          return; // Wait for the consult if exist
        }

        if (referredOutConsult || consult) {
          const consultGuid = referredOutConsult
            ? referredOutConsult?.guid
            : consult?.guid;

          await openPatientVideoVisitLink(consultGuid); // Open external link
        } else {
          return; // Wait for the consult if exist
        }
        break;
      case redirectToMapping[destinations.treatmentPlan]:
        if (treatmentPlanStateLoading || !prescription) {
          return; //Wait for values to appear
        } else if (!treatmentPlanState) {
          break;
        }

        linkNavigateTo = `/${redirectToMapping[destinations.treatmentPlan]}/${prescription.prescriptionId}/${treatmentPlanState}`;
        break;
      case redirectToMapping[destinations.refillIntake]:
        if (!prescription) {
          return; //Wait for prescription
        }

        linkNavigateTo = `/${redirectToMapping[destinations.refillIntake]}/${prescription?.refillSessionId}/0`;
        break;
      case redirectToMapping[destinations.labWork]:
        linkNavigateTo = `/${redirectToMapping[destinations.labWork]}/_/true`;
        break;
      case redirectToMapping[destinations.afterVisitSummary]:
      case redirectToMapping[destinations.messages]:
        linkNavigateTo = `/${route}`;
        break;
      default:
        navigate("/");
    }

    //Make sure there is a screen to return to
    navigateWithRootRoute(linkNavigateTo);
  }, [
    consult,
    consultLoading,
    currentUser?.uid,
    isAuthenticated,
    isMedicationOrderStatusLoading,
    medicationOrderStatus,
    navigate,
    navigateWithRootRoute,
    openPatientVideoVisitLink,
    orderNumber,
    posthog,
    prescription,
    query,
    referredOutConsult,
    referredOutConsultLoading,
    treatmentPlanState,
    treatmentPlanStateLoading,
  ]);

  useEffect(() => {
    void CapApp.addListener("appUrlOpen", (data: { url: string }) => {
      // Handle deep linking
      console.log("App opened with URL:", data.url);

      if (!data?.url) {
        console.warn("No URL provided in appUrlOpen");
        return;
      }
      const path = extractPathFromUrl(data.url);
      if (path) {
        navigate(path);
      }
    });

    // Handle initial URL
    const checkInitialURL = async () => {
      const result = await CapApp.getLaunchUrl();
      const route = window.location.pathname;

      if (isDesktop && "/entry" === route) {
        await redirectBasedOnPassedParams();
      }

      if (result && "url" in result) {
        // Handle the path
        console.log("Initial URL:", result.url);
        const path = extractPathFromUrl(result.url);

        if (path) {
          navigate(path);
        }
      }
    };
    void checkInitialURL();
  }, [navigate, isDesktop, query, redirectBasedOnPassedParams]);

  // TODO: To add loader for destinations.appointmentVideoLink case
  // Use externalLinkLoading from useGetPatientVideoVisitLink
  return children;
};

export default AppUrlListener;
