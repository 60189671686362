import { useQuery } from "@tanstack/react-query";
import { getProductById, getUserData } from "../../services/paymentsService";
import { useAuth } from "../../context/AuthContext";

const useProductOrderStatus = (futurhealthPlusId: string) => {
  const { currentUser } = useAuth();

  // Fetch user data from payments service
  const {
    data: userData,
    error: userError,
    isLoading: isUserLoading,
  } = useQuery({
    queryKey: ["payment service: user data", futurhealthPlusId],
    queryFn: () =>
      getUserData({
        futurhealthPlusId: currentUser?.uid ?? "",
        user: currentUser,
      }),
    enabled: !!currentUser,
  });
  // Extract product ID from user data
  const productId = userData?.data?.[0]?.edges?.subscriptions?.length
    ? userData.data[0]?.edges?.subscriptions.find((s) => s.active)
        ?.product_id || undefined
    : undefined;

  // Fetch product data
  const {
    data: productData,
    error: productError,
    isLoading: isProductLoading,
  } = useQuery({
    queryKey: ["payment service: product data", productId],
    queryFn: () => getProductById({ productId, user: currentUser }),
    enabled: !!productId && !!currentUser,
  });

  return {
    userData,
    userError,
    isUserLoading,
    productData,
    productError,
    isProductLoading,
  };
};

export default useProductOrderStatus;
