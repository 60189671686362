import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { PaymentMethod, PaymentOptions } from "../typings";

import stripeStaging from "../../config/stripe-staging.json";
import stripeProduction from "../../config/stripe-staging.json";
import { SetupIntent } from "@stripe/stripe-js";
import { User } from "firebase/auth";
import {
  AffirmPrice,
  MembershipMonthlyPrice,
  MembershipPrice,
  MembershipPriceSB,
} from "../typings/enums";
import {
  CreateCustomerPayload,
  CreateSubscriptionPayload,
  ICustomer,
  TaxCalculationsPayload,
} from "../typings/stripeTypes";
import { getGoalMonths } from "../utils/weightUtils";
import { formatPrice } from "../components/paymentUtil";
import { getMultiMonthProgramName, ProductNames } from "../utils/surveyUtils";

interface StripeConfig {
  price: string;
  product: string;
  unitAmount: number;
}

const _stripeConfig: Record<string, Record<string, StripeConfig>> = {
  staging: stripeStaging,
  production: stripeProduction,
};

const stripeEnv =
  import.meta.env.MODE === "production" ? "production" : "staging";
export const stripeConfig = _stripeConfig[stripeEnv];

export enum Membership {
  SemaAffirmSubscription = "membership-sema-affirm",
  BrandedMedsSubscription = "membership-meds-affirm",
  BrandedMedsMonthlySubscription = "membership-meds-monthly-affirm",
  YearlySubscription = "membership-yearly",
  MonthlySubscription = "membership-monthly",
  MultiMonthSubscription = "membership-multi-month",
  NutritionProgram = "nutrition-program",
  Rx = "Rx",
}

export enum Offerid {
  Sema = "smd-sema",
  Ozempic = "smd-ozempic",
  Mounjaro = "smd-mounjaro",
  SemaMonthly = "smd-sema-monthly",
  OzempicMonthly = "smd-ozempic-monthly",
  MounjaroMonthly = "smd-mounjaro-monthly",
  SemaSplitPayment = "smd-sema-split",
}

export enum Medications {
  Sema = "smd-sema",
  Ozempic = "smd-ozempic",
  Mounjaro = "smd-mounjaro",
  MounjaroInsurance = "smd-amz-moun",
  SemaMonthly = "smd-sema-monthly",
  OzempicMonthly = "smd-ozempic-monthly",
  MounjaroMonthly = "smd-mounjaro-monthly",
}

export enum Products {
  Metformin = "metformin",
  Semaglutide = "semaglutide",
  Tirzepitide = "tirzepitide",
}

export const getPaymentOptionsLegacy = (
  programName: ProductNames,
  months: number
): PaymentOptions[] => {
  const membershipPrice = MembershipPrice;
  const discount =
    MembershipPrice.monthly * months - MembershipPrice.oneTime * months;

  return [
    {
      name: programName,
      mainText: "In full",
      previousPrice: "$129",
      monthlyPrice: "$99",
      mainDescription: `Pay $${MembershipPrice.oneTime * months} today`,
      discount: `YOU SAVE $${discount}`,
      introRate: false,
    },
    {
      name: ProductNames.SemaglutideMonthly,
      mainText: "Monthly",
      monthlyPrice: `$${membershipPrice.monthly}`,
      mainDescription: `Pay $${MembershipPrice.monthly} today • $${MembershipPrice.monthly * months} Total`,
      introRate: false,
    },
  ];
};

export const getPaymentOptions = (
  startWeight: string,
  goalWeight: string,
  isMultiMonthEnabled: boolean = true,
  isAffirmFeatureFlagEnabled: boolean,
  affirmAmount: string,
  hasAffirm: boolean
): PaymentOptions[] => {
  const months = getGoalMonths(startWeight, goalWeight);

  const membershipPrice = MembershipPriceSB;
  const programName = getMultiMonthProgramName(months);
  const discount =
    MembershipPriceSB.monthly * months - MembershipPriceSB.oneTime * months;
  const multiMonthMemberships: PaymentOptions[] = [
    {
      name: programName,
      mainText: `In full`,
      previousPrice: `$${membershipPrice.monthly}`,
      monthlyPrice: `$${membershipPrice.oneTime}`,
      mainDescription: `Pay $${(membershipPrice.oneTime * months).toLocaleString()} today for your ${months} month supply`,
      discount: `YOU SAVE $${discount} TODAY!`,
      introRate: false,
    },
    {
      name: ProductNames.SemaglutideBundleMonthly,
      mainText: "Monthly",
      monthlyPrice: `$${membershipPrice.monthly}`,
      mainDescription: `For ${months} months`,
      introRate: false,
    },
  ];

  if (isAffirmFeatureFlagEnabled && hasAffirm) {
    multiMonthMemberships.push({
      name: ProductNames.SemaglutideBundle6MonthsAffirm,
      mainDescription: `Pay ${formatPrice(AffirmPrice.semaSixMonths, false)} today for your 6-month program`,
      introRate: false,
      monthlyPrice: `${formatPrice(AffirmPrice.asLowAsPrice, true)}`,
      hasAffirm,
      affirmAmount: formatPrice(AffirmPrice.asLowAsPrice, true),
    });
  }

  const singleMonthMemberships = [
    {
      name: ProductNames.SemaglutideBundleMonthly,
      mainText: "Monthly plan",
      previousPrice: `$${MembershipMonthlyPrice.monthlyOriginalPrice}`,
      monthlyPrice: `$${MembershipMonthlyPrice.monthly}`,
      mainDescription: `Lose all the weight`,
      discount: `YOU SAVE $${MembershipMonthlyPrice.discount}`,
      introRate: false,
    },
  ];

  return isMultiMonthEnabled ? multiMonthMemberships : singleMonthMemberships;
};

const stripeCloudFunctionsBaseURL = `${import.meta.env.VITE_API_GATEWAY_URL}/stripe`;

interface ClientSecretResponse {
  clientSecret: string;
}

const createPaymentIntent = async (
  postBody: { items: string[]; customerId: string },
  token: string
) => {
  const url = `${stripeCloudFunctionsBaseURL}/create-payment-intent`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post<ClientSecretResponse>(
    url,
    postBody,
    config
  );
  return response.data;
};

// todo: post body type
const createStripeCustomer = async (
  postData: CreateCustomerPayload,
  token: string
) => {
  const url = `${stripeCloudFunctionsBaseURL}/create-customer`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post(url, postData, config);
  return response.data;
};

const createSubscription = async (
  postData: Partial<CreateSubscriptionPayload>,
  token: string
) => {
  const url = `${stripeCloudFunctionsBaseURL}/create-subscription`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post(url, postData, config);
  return response.data;
};

interface SetupPaymentIntentRequest {
  customerId?: string;
  paymentMethod?: string;
  confirm?: boolean;
}

const createSetupIntents = async (
  payload: SetupPaymentIntentRequest,
  token?: string
): Promise<SetupIntent> => {
  if (!payload.customerId) throw new Error("customerId is required");
  if (!token) throw new Error("User is not authenticated");
  const url = `${stripeCloudFunctionsBaseURL}/create-setup-intents`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post(url, payload, config);
  return response.data;
};

const fetchAllPaymentMethods = async (
  user: User | null,
  customerId?: string
) => {
  if (!user || !customerId) throw new Error("User is not authenticated");

  const token = await user.getIdToken();
  const url = `${stripeCloudFunctionsBaseURL}/retrieve-payment-methods?customerId=${customerId}`;

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.get<AxiosResponse<PaymentMethod[]>>(url, config);
  return response.data.data;
};

const fetchCustomer = async (
  token?: string,
  customerId?: string
): Promise<ICustomer> => {
  if (!token || !customerId) throw new Error("User is not authenticated");

  const url = `${stripeCloudFunctionsBaseURL}/retrieve-customer?customerId=${customerId}`;

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.get<ICustomer>(url, config);
  return response.data;
};

const updateCustomer = async ({
  token,
  customerId,
  paymentMethodId,
}: {
  token?: string;
  customerId?: string;
  paymentMethodId: string;
}): Promise<AxiosResponse<ICustomer>> => {
  if (!token || !customerId) throw new Error("User is not authenticated");
  const url = `${stripeCloudFunctionsBaseURL}/update-customer?customerId=${customerId}`;

  const body = {
    invoice_settings: {
      default_payment_method: paymentMethodId,
    },
  };

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.post(url, body, config);
  return response.data;
};

const detachPaymentMethod = async (
  paymentMethodId?: string,
  token?: string
) => {
  if (!paymentMethodId) throw new Error("paymentMethodId is required");
  if (!token) throw new Error("User is not authenticated");
  const url = `${stripeCloudFunctionsBaseURL}/detach-payment-method`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  await axios.post(url, { paymentMethodId }, config);
};

const fetchLatestInvoice = async (
  customerId: string,
  subscriptionId: string,
  token: string
) => {
  if (!token || !customerId) throw new Error("User is not authenticated");
  const url = `${stripeCloudFunctionsBaseURL}/retrieve-upcoming-invoice?customerId=${customerId}&subscriptionId=${subscriptionId}`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(url, config);
  return response.data;
};
interface RetrieveTaxCalculationsResponse {
  id: string;
  object: string;
  amount_total: number;
  currency: string;
  customer: string;
  customer_details: {
    address: {
      line1: string;
      line2?: string;
      city: string;
      state: string;
      postal_code: string;
      country: string;
    };
    address_source: string;
    ip_address: string;
    tax_ids: string[];
    taxability_override: string;
  };
  expires_at: number;
  livemode: boolean;
  ship_from_details: Record<string, unknown>; // Assuming ship_from_details is a generic object
  shipping_cost: number;
  tax_amount_exclusive: number;
  tax_amount_inclusive: number;
  tax_breakdown: {
    amount: number;
    inclusive: boolean;
    tax_rate_details: {
      country: string;
      percentage_decimal: string;
      state: string;
      tax_type: string;
    };
    taxability_reason: string;
    taxable_amount: number;
  }[];
  tax_date: number;
}

const retrieveTaxCalculations = async (
  token: string,
  payload: TaxCalculationsPayload
) => {
  const url = `${stripeCloudFunctionsBaseURL}/retrieve-tax-calculations`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post<RetrieveTaxCalculationsResponse>(
    url,
    payload,
    config
  );
  return response.data;
};

interface PaymentMethodAttachRequest {
  customerId: string;
  paymentMethod: string;
}
const paymentMethodAttach = async (
  payload: PaymentMethodAttachRequest,
  token?: string
): Promise<SetupIntent> => {
  if (!payload.customerId) throw new Error("customerId is required");
  if (!token) throw new Error("User is not authenticated");
  const url = `${stripeCloudFunctionsBaseURL}/attach-payment-method`;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post(url, payload, config);
  return response.data;
};

export {
  createSubscription,
  createPaymentIntent,
  createStripeCustomer,
  createSetupIntents,
  fetchAllPaymentMethods,
  fetchCustomer,
  updateCustomer,
  detachPaymentMethod,
  fetchLatestInvoice,
  paymentMethodAttach,
  retrieveTaxCalculations,
};
