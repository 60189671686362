import { useQuery } from "@tanstack/react-query";
import {
  getLatestRefillSessionByUser,
  getRefillSession,
} from "../../services/firestoreService";

export const useRefillSession = (id: string, userId: string) => {
  return useQuery({
    queryKey: ["refillSession", id],
    queryFn: () => getRefillSession(id, userId),
    enabled: !!id && !!userId,
  });
};

export const useLatestRefillSessionByUser = (
  userId: string,
  completed = false
) => {
  return useQuery({
    queryKey: ["latest refillSession", { userId, completed }],
    queryFn: () => getLatestRefillSessionByUser(userId, completed),
    enabled: !!userId,
  });
};
