import { Avatar, Box, Flex, Image } from "@mantine/core";
import { MedicationOrderStatus } from "./medicationOrderUtils";
import { TreatmentPlanState } from "../typings/enums";
import { colors } from "../theme/colors";

export const createAvatar = (src: string) => {
  return <Avatar src={src} radius="50%" size="lg" />;
};

const createLayeredImages = (
  backgroundSrc: string,
  overlaySrc: string,
  bg = colors.purpleCream
) => {
  return (
    <Box
      style={{
        position: "relative",
        width: "50px",
        height: "50px",
      }}
    >
      <Flex bg={bg} style={{ overflow: "hidden", borderRadius: 100 }}>
        <Image
          src={backgroundSrc}
          alt="Background Image"
          style={{ width: "100%", height: "100%" }}
        />
        <Image
          src={overlaySrc}
          alt="Overlay Image"
          style={{
            position: "absolute",
            bottom: -5,
            right: -5,
            width: "20px",
            height: "20px",
          }}
        />
      </Flex>
    </Box>
  );
};

const statusRequiringAlert = new Set([
  TreatmentPlanState.RefillCheckIn,
  TreatmentPlanState.ContinueCheckIn,
  TreatmentPlanState.AppointmentRequired,
  TreatmentPlanState.LabsRequired,
  TreatmentPlanState.AdditionalSetupRequired,
  TreatmentPlanState.CareUpdate,
  MedicationOrderStatus.OnHoldForLabs,
  MedicationOrderStatus.OnHoldForPayment,
  MedicationOrderStatus.Canceled,
  MedicationOrderStatus.Refunded,
  MedicationOrderStatus.RequiresConfirmation,
]);

const statusRequiringCheck = new Set<
  MedicationOrderStatus | TreatmentPlanState
>([MedicationOrderStatus.Delivered, MedicationOrderStatus.ConfirmedDelivered]);

export const getAvatarImage = (
  status: MedicationOrderStatus | TreatmentPlanState | undefined,
  imagePath: string | undefined
) => {
  if (!imagePath) {
    if (status && statusRequiringAlert.has(status))
      return createLayeredImages(
        "/images/default-medication.png",
        "/alert-badge.svg"
      );
    return createAvatar("/images/default-medication.png");
  }
  if (status === TreatmentPlanState.MessageFromProvider) {
    return createLayeredImages(imagePath, "/dots-badge.svg");
  }

  if (status && statusRequiringAlert.has(status)) {
    return createLayeredImages(imagePath, "/alert-badge.svg", colors.peach);
  }

  if (status && statusRequiringCheck.has(status)) {
    return createLayeredImages(imagePath, "/green-check.svg");
  }

  return createAvatar(imagePath);
};

export const getGreeting = (date: Date) => {
  const time = date.getHours();
  if (time >= 5 && time < 12) return "Good Morning!";
  if (time >= 12 && time < 17) return "Good Afternoon!";
  return "Good Evening!";
};
