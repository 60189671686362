import { useRef, useCallback } from "react";

export const useOnceOnPage = <T extends (...args: never[]) => void>(
  fn: T
): T => {
  const hasBeenCalledRef = useRef(false);

  return useCallback(
    (...args: Parameters<T>) => {
      if (!hasBeenCalledRef.current) {
        fn(...args);
        hasBeenCalledRef.current = true;
      }
    },
    [fn]
  ) as T;
};
