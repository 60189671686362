import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../context/AuthContext";
import {
  getUserProfile,
  getStripePayments,
} from "../../services/firestoreService";
import {
  MedicationOrderStatus,
  determineMedicationOrderStatus,
} from "../../utils/medicationOrderUtils";
import { MedicationOrderStatusParams, StripePayment } from "../../typings";
import { useLatestPrescriptionRealtime } from "./useLatestPrescriptionRealtime";
import useProductOrderStatus from "./useProductOrderStatus";
import { getTransactions } from "../../services/paymentsService";
import { TransactionData } from "../../typings/paymentsServiceTypes";

interface UseMedicationOrderStatus {
  status: MedicationOrderStatus;
  orderNumber: string;
  isLoading: boolean;
}

type MedicationOrderPayment = TransactionData | StripePayment | null;

export const useMedicationOrderStatus = (): UseMedicationOrderStatus => {
  const { currentUser } = useAuth();

  const { productData } = useProductOrderStatus(currentUser?.uid ?? "");
  const { isLoading: userProfileLoading, data: userProfile } = useQuery({
    queryKey: ["userProfile", currentUser?.uid],
    queryFn: () => getUserProfile(currentUser!),
    enabled: !!currentUser,
  });

  const { data: medicationOrderPayment, isLoading: medicationOrderLoading } =
    useQuery<MedicationOrderPayment>({
      queryKey: ["medicationOrderPayment", currentUser?.uid],
      queryFn: async () => {
        try {
          if (userProfile?.paymentsService) {
            const transactions = await getTransactions(currentUser, true);
            if (!transactions || transactions.length === 0) {
              return null;
            }
            return {
              ...transactions[0],
              status: transactions[0].intent_outcome,
            };
          } else {
            const payments = await getStripePayments(currentUser?.uid ?? "");
            return (
              payments?.find(
                (payment) => payment.productCategory === "medication"
              ) ?? null
            );
          }
        } catch (error) {
          console.error("Error fetching medication order payment:", error);
          throw error;
        }
      },
      enabled:
        !!currentUser &&
        !productData?.description &&
        !userProfileLoading &&
        !!userProfile, // Only run this query if productData is available which indicates it's a bundle,
    });

  const prescription = useLatestPrescriptionRealtime();
  const medicationOrderStatus = determineMedicationOrderStatus({
    medicationOrderPayment,
    prescription,
    bundled: !!productData?.description,
  } as MedicationOrderStatusParams);

  return {
    status: medicationOrderStatus ?? MedicationOrderStatus.Pending,
    orderNumber: prescription?.externalOrderId ?? "",
    isLoading: userProfileLoading || medicationOrderLoading,
  };
};
