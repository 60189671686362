import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { StorageProvider } from "./context/StorageContext";
import { ToastProvider } from "./context/ToastContext";
import { ZendeskProvider } from "./context/ZendeskContext";
import { useAppUpdate } from "./hooks/useAppUpdate";
import { initStorage } from "./hooks/storageService";
import { NutritionProvider } from "./context/NutritionContext";

/* Lazy Load Components */
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const ActivateNotifications = lazy(
  () => import("./pages/ActivateNotifications")
);
const AfterVisitSummaries = lazy(() => import("./pages/AfterVisitSummaries"));
const AfterVisitSummaryDetails = lazy(
  () => import("./pages/AfterVisitSummaryDetails")
);
const AppointmentDetails = lazy(() => import("./pages/AppointmentDetails"));
const CheckoutReturn = lazy(() => import("./pages/CheckoutReturn"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const CreateAppt = lazy(() => import("./pages/create-appointment/CreateAppt"));
const AppointmentSelector = lazy(
  () => import("./pages/create-appointment/AppointmentSelector")
);
const FunnelOrderComplete = lazy(
  () => import("./pages/create-appointment/FunnelOrderComplete")
);
const Disqualified = lazy(() => import("./pages/Disqualified"));
const Login = lazy(() => import("./pages/Login"));
const MedicationInstructions = lazy(
  () => import("./pages/MedicationInstructions")
);
const MedicationOrderDetails = lazy(
  () => import("./pages/MedicationOrderDetails")
);
const Messages = lazy(() => import("./pages/Messages"));
const NotificationSettings = lazy(() => import("./pages/NotificationSettings"));
const Notifications = lazy(() => import("./pages/Notifications"));
const PrescriptionDetails = lazy(() => import("./pages/PrescriptionDetails"));
const Rate = lazy(() => import("./pages/Rate"));
const ReportSideEffects = lazy(() => import("./pages/ReportSideEffects"));
const RescheduleAppt = lazy(() => import("./pages/RescheduleAppt"));
const SideEffectDetails = lazy(() => import("./pages/SideEffectDetails"));
const StartLabWorkPage = lazy(() => import("./pages/StartLabWork"));
const TreatmentPlan = lazy(() => import("./pages/TreatmentPlan"));
const PaymentMethodsPage = lazy(
  () => import("./pages/account/PaymentMethodsPage")
);
const Tabs = lazy(() => import("./tabs/Tabs"));
const AddNewCard = lazy(() => import("./pages/AddNewCard"));
const MedicalCitations = lazy(() => import("./pages/MedicalCitations"));
const MembershipManagementSwitcher = lazy(
  () => import("./pages/MembershipManagementSwitcher")
);
const NutritionIntroPage = lazy(() => import("./pages/NutritionIntroPage"));
const MealCardPage = lazy(() => import("./pages/nutrition/MealCardPage"));
const RecipeLibrary = lazy(
  () => import("./pages/nutrition/recipeLibrary/RecipeLibrary")
);
const RecipesFilters = lazy(
  () => import("./pages/nutrition/recipeLibrary/RecipesFilters")
);
const RecipeInLibraryCard = lazy(
  () => import("./pages/nutrition/recipeLibrary/RecipeInLibraryCard")
);
const RefillIntake = lazy(() => import("./pages/RefillIntake"));
const AddShippingAddress = lazy(() => import("./pages/AddShippingAddress"));
const SurveyQuestion = lazy(() => import("./pages/survey/SurveyQuestion"));
const PersonalDetails = lazy(
  () => import("./pages/personal-details/PersonalDetails")
);

const CheckoutApprovalSwitcher = lazy(
  () => import("./pages/checkout/approval/CheckoutApprovalSwitcher")
);

const CheckoutResults = lazy(() => import("./pages/checkout/CheckoutResults"));
const CheckoutAddress = lazy(() => import("./pages/checkout/CheckoutAddress"));
const CheckoutPayment = lazy(() => import("./pages/checkout/CheckoutPayment"));
const CheckoutAccount = lazy(() => import("./pages/checkout/CheckoutAccount"));

const TelemedicineMedicationAgreement = lazy(
  () => import("./pages/create-appointment/TelemedicineMedicationAgreement")
);

import "@mantine/dates/styles.css";
import "@mantine/charts/styles.css";
// Customer google typeface
import "typeface-inter";
import "typeface-syne";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@mantine/code-highlight/styles.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { SplashScreen } from "@capacitor/splash-screen";
import "@mantine/carousel/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "./theme/variables.css";
import { LDProvider } from "launchdarkly-react-client-sdk";
import ScheduleAppt from "./pages/ScheduleAppt";
import PrivateRoute from "./components/PrivateRoute";
import { FHLoader } from "./components/FHLoader";
import { CheckoutRoutes } from "./typings/enums";
import CheckoutRoute from "./components/CheckoutRoute";
import SurveyRoute from "./components/SurveyRoute";
import AppUrlListener from "./components/AppUrlListener/AppUrlListener";
import CheckoutApprovalRoute from "./components/CheckoutApprovalRoute";

const launchDarklyClientSideID = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID;
setupIonicReact({
  animated: true, //!isPlatform('mobileweb'),
});

const queryClient = new QueryClient();

const App: React.FC = () => {
  useAppUpdate();

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  useEffect(() => {
    initStorage();
  }, []);

  return (
    <IonApp>
      <LDProvider clientSideID={launchDarklyClientSideID}>
        <QueryClientProvider client={queryClient}>
          <NutritionProvider>
            <StorageProvider>
              <ZendeskProvider>
                <IonReactRouter>
                  <AppUrlListener>
                    <ToastProvider>
                      <Suspense fallback={<FHLoader />}>
                        <IonRouterOutlet>
                          {/* Authed routes */}
                          <PrivateRoute exact path="/rate" component={Rate} />
                          <PrivateRoute
                            exact
                            path="/treatment-plan/:prescriptionId/:state"
                            component={TreatmentPlan}
                          />
                          <PrivateRoute
                            exact
                            path="/medication-instructions/:medicationId"
                            component={MedicationInstructions}
                          />
                          <PrivateRoute
                            exact
                            path="/prescription-details/:medicationId"
                            component={PrescriptionDetails}
                          />
                          <PrivateRoute
                            exact
                            path="/after-visit-summaries"
                            component={AfterVisitSummaries}
                          />
                          <PrivateRoute
                            exact
                            path="/create-appointment/0/:episodeId?"
                            component={CreateAppt}
                          />
                          <PrivateRoute
                            exact
                            path="/create-appointment/1/:consultId?/:episodeId?"
                            component={AppointmentSelector}
                          />
                          <PrivateRoute
                            exact
                            path="/create-appointment/2"
                            component={FunnelOrderComplete}
                          />
                          <PrivateRoute
                            exact
                            path="/telemedicine-agreement"
                            component={TelemedicineMedicationAgreement}
                          />
                          <PrivateRoute
                            exact
                            path="/schedule-appointment/:index/:selectedDate?"
                            component={ScheduleAppt}
                          />
                          <PrivateRoute
                            exact
                            path="/reschedule-appointment/:index/:selectedDate?"
                            component={RescheduleAppt}
                          />
                          <PrivateRoute path="/tabs" component={Tabs} />
                          <PrivateRoute
                            exact
                            path="/checkout/return"
                            component={CheckoutReturn}
                          />
                          <PrivateRoute
                            path="/appointment/details/:guid"
                            component={AppointmentDetails}
                          />
                          <PrivateRoute
                            path="/medication-order/:status/:orderNumber?"
                            component={MedicationOrderDetails}
                          />
                          <PrivateRoute
                            path="/side-effects"
                            component={SideEffectDetails}
                          />
                          <PrivateRoute
                            path="/start-lab-work/:guid?/:downloadPdfOnLoad?"
                            component={StartLabWorkPage}
                            exact
                          />
                          <PrivateRoute
                            path="/report-side-effects"
                            component={ReportSideEffects}
                          />
                          <PrivateRoute
                            path="/after-visit-summaries/details/:consultId"
                            component={AfterVisitSummaryDetails}
                            exact
                          />
                          <PrivateRoute
                            exact
                            path="/notifications"
                            component={Notifications}
                          />
                          <PrivateRoute
                            exact
                            path="/notification-settings"
                            component={NotificationSettings}
                          />
                          <PrivateRoute
                            exact
                            path="/nutrition-quiz"
                            component={NutritionIntroPage}
                          />
                          <PrivateRoute
                            path="/personal-details"
                            component={PersonalDetails}
                          />
                          <PrivateRoute
                            path="/reset-password"
                            component={ResetPassword}
                          />
                          <PrivateRoute
                            path="/add-shipping-address"
                            component={AddShippingAddress}
                          />
                          <PrivateRoute path="/messages" component={Messages} />
                          <PrivateRoute
                            exact
                            path="/add-new-card"
                            component={AddNewCard}
                          />
                          <PrivateRoute
                            exact
                            path="/medical-citations"
                            component={MedicalCitations}
                          />
                          <PrivateRoute
                            exact
                            path="/membership-management"
                            component={MembershipManagementSwitcher}
                          />
                          <PrivateRoute
                            path="/refill-funnel/:id/:index"
                            component={RefillIntake}
                            exact
                          />
                          {/* Publicly accessible routes */}
                          <SurveyRoute
                            path="/survey/:slug/:index"
                            component={SurveyQuestion}
                          />
                          <Route
                            path="/disqualified"
                            component={Disqualified}
                          />
                          <Route
                            exact
                            path="/create-account"
                            component={CreateAccount}
                          />
                          <Route
                            exact
                            path="/activate-notifications"
                            component={ActivateNotifications}
                          />
                          <Route exact path="/login" component={Login} />
                          <CheckoutApprovalRoute
                            exact
                            path={CheckoutRoutes.Approval}
                            component={CheckoutApprovalSwitcher}
                          />
                          <CheckoutRoute
                            exact
                            path={CheckoutRoutes.Results}
                            component={CheckoutResults}
                          />
                          <CheckoutRoute
                            exact
                            path={CheckoutRoutes.Address}
                            component={CheckoutAddress}
                          />
                          <CheckoutRoute
                            exact
                            path={CheckoutRoutes.Payment}
                            component={CheckoutPayment}
                          />
                          <CheckoutRoute
                            exact
                            path={CheckoutRoutes.Account}
                            component={CheckoutAccount}
                          />
                          <PrivateRoute
                            exact
                            path="/payment-methods"
                            component={PaymentMethodsPage}
                          />
                          <PrivateRoute
                            exact
                            path="/nutrition-meal-card/:mealIndex/:date/:weekNumberInString/:weekDay"
                            component={MealCardPage}
                          />
                          <PrivateRoute
                            exact
                            path="/recipes-library/:swapMealId/:swapMealLabel/:selectedWeek/"
                            component={RecipeLibrary}
                          />
                          <PrivateRoute
                            exact
                            path="/recipe-card"
                            component={RecipeInLibraryCard}
                          />
                          <PrivateRoute
                            exact
                            path="/recipes-filters"
                            component={RecipesFilters}
                          />
                          <Route exact path="/">
                            <Redirect to="/tabs" />
                          </Route>
                        </IonRouterOutlet>
                      </Suspense>
                    </ToastProvider>
                  </AppUrlListener>
                </IonReactRouter>
              </ZendeskProvider>
            </StorageProvider>
          </NutritionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LDProvider>
    </IonApp>
  );
};

export default App;
