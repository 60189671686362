import { Flex, Modal, Space, Text } from "@mantine/core";
import FHButton from "../../FHButton";
import { ModalDivider } from "../ModalDivider";
import { isPlatform } from "@ionic/core";
import { TextPoint } from "./TextPoint";
import { colors } from "../../../theme/colors";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import "./NextStepsPrescriptionOrderingModal.css";

const isDesktop = isPlatform("desktop");

const TEXTS = [
  {
    title: "Redirect to Amazon Pharmacy:",
    text: "Click “Continue” to proceed to Amazon Pharmacy and complete your order.",
    points: [{ bold: "", point_text: "" }],
  },
  {
    title: "Sign In or Create an Account:",
    text: "Log into your Amazon account or create oneto access your prescription.",
    points: [{ bold: "", point_text: "" }],
  },
  {
    title: "View Your Prescription:",
    text: "Once logged in, review your prescription details.",
    points: [{ bold: "", point_text: "" }],
  },
  {
    title: "Check Pricing:",
    text: "",
    points: [
      {
        bold: "• If Approved:",
        point_text: "See both your insurance copay and our discount price.",
      },
      {
        bold: "• If Denied:",
        point_text: "Use our discount codeto complete your purchase.",
      },
    ],
  },
  {
    title: "Complete Checkout:",
    text: "Follow the on-screen steps to finalize your order on Amazon Pharmacy.",
    points: [{ bold: "", point_text: "" }],
  },
];

export const NextStepsPrescriptionOrderingModal = ({
  opened,
  onCloseModal,
  onContinue,
}: {
  opened: boolean;
  onCloseModal: () => void;
  onContinue: () => void;
}) => {
  const postHog = usePostHog();

  useEffect(() => {
    if (opened) {
      postHog.capture("amazon-modal-CA_screen_viewed");
    }
  }, [opened, postHog]);

  return (
    <Modal
      className="nextStepsPrescriptionOrderingModal"
      opened={opened}
      onClose={onCloseModal}
      closeOnClickOutside
      withCloseButton={false}
      transitionProps={{ transition: "slide-up", duration: 300 }}
    >
      {isDesktop ? null : <ModalDivider />}
      <Flex pt={10} w={"100%"} align={"center"} direction={"column"}>
        <Text ta={"center"} mb={20} ff={"Inter"} fw={"600"} fz={28}>
          Next steps for ordering your prescription:
        </Text>

        <Flex w={"100%"} direction={"column"}>
          {TEXTS.map(({ title, text, points }, index) => (
            <TextPoint
              key={index + title}
              step={`${index + 1}.`}
              title={title}
              text={text}
              listPoints={points}
            />
          ))}
        </Flex>

        <Flex
          style={{
            borderWidth: 1,
            border: "1px solid",
            borderColor: colors.purpleCream,
            borderRadius: 10,
            padding: 14,
          }}
        >
          <Text fz={12} c={colors.darkGray}>
            Notice: sometimes, it may take up to 24 hours for our systems to
            update. If you’ve already paid for your medications on Amazon, sit
            tight — we’re working on getting everything in sync!
          </Text>
        </Flex>
        <Flex w={"100%"} mt={20}>
          <FHButton
            onClick={onCloseModal}
            fullWidth
            mb={"10px"}
            variant={"tertiary"}
          >
            <Text fz={16} fw={"600"} ff={"inter"}>
              Dismiss
            </Text>
          </FHButton>
          <Space w={30}></Space>
          <FHButton onClick={onContinue} fullWidth mb={"10px"}>
            <Text fz={16} fw={"600"} ff={"inter"}>
              Continue
            </Text>
          </FHButton>
        </Flex>
      </Flex>
    </Modal>
  );
};
