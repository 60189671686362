import { Flex, Text } from "@mantine/core";

interface ITextPoint {
  step: string;
  title: string;
  text?: string;
  listPoints?: { bold: string; point_text: string }[];
}
export const TextPoint = ({ step, title, text, listPoints }: ITextPoint) => {
  return (
    <Flex mb={12}>
      <Text mr={8} fw={600} fz={14}>
        {step}
      </Text>
      <Text fw={600} fz={14}>
        {title}
        {text ? (
          <Text display={"flex"} component="span" fz={14}>
            {text}
          </Text>
        ) : null}
        {listPoints?.length &&
        listPoints[0].bold &&
        listPoints[0].point_text ? (
          <Flex direction={"column"} component="span">
            {listPoints.map(({ bold, point_text }, index) => (
              <Text
                mt={10}
                key={bold + point_text + index}
                component="span"
                fz={14}
              >
                {bold ? (
                  <Text component="span" mt={10} fw={600} fz={14}>
                    {bold}{" "}
                    <Text component="span" fz={14}>
                      {point_text}
                    </Text>
                  </Text>
                ) : null}
              </Text>
            ))}
          </Flex>
        ) : null}
      </Text>
    </Flex>
  );
};
