import { Membership } from "../services/stripe";
import {
  MembershipPriceSB,
  MembershipPrice,
  CheckoutRoutes,
  AffirmPrice,
} from "../typings/enums";
import { ProductNames } from "../utils/surveyUtils";

// Legacy component
export const getProgramPrice = (
  subscription: Membership,
  monthsForPrice: number,
  semaglutideBundle: boolean
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return semaglutideBundle
        ? MembershipPriceSB.programPrice
        : MembershipPrice.monthly;
    case Membership.NutritionProgram:
      return MembershipPrice.nutritionProgram;
    default: // MultiMonthSubscription
      return semaglutideBundle
        ? MembershipPriceSB.programPrice * monthsForPrice
        : MembershipPrice.oneTime * monthsForPrice;
  }
};

// Legacy component
export const getPharmacyCoverage = (
  subscription: Membership,
  monthsForPrice: number
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return MembershipPriceSB.pharmacyCoverage;
    default: // Membership.MultiMonthSubscription:
      return MembershipPriceSB.pharmacyCoverage * monthsForPrice;
  }
};
export const getDiscountPriceSplitPayment = (
  subscription: Membership | ProductNames,
  monthsForPrice: number
) => {
  switch (subscription) {
    case ProductNames.SemaglutideMonthly:
      return 39;
    default: // Membership.MultiMonthSubscription:
      return MembershipPrice.discount * monthsForPrice;
  }
};

export const getDiscountPrice = (
  subscription: Membership | ProductNames,
  monthsForPrice: number
) => {
  switch (subscription) {
    case ProductNames.SemaglutideBundleMonthly:
      return 0;
    default: // Membership.MultiMonthSubscription:
      return MembershipPriceSB.discount * monthsForPrice;
  }
};

export const getTotalPriceBeforeTax = (
  subscription: Membership,
  monthsForPrice: number,
  semaglutideBundle: boolean
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return semaglutideBundle
        ? MembershipPriceSB.monthly
        : MembershipPrice.monthly;
    case Membership.NutritionProgram:
      return MembershipPrice.nutritionProgram;
    default: // MultiMonthSubscription
      return semaglutideBundle
        ? MembershipPriceSB.oneTime * monthsForPrice
        : MembershipPrice.oneTime * monthsForPrice;
  }
};

export const getTotalPriceBeforeTaxWithoutDiscountSplitPayment = (
  subscription: Membership | ProductNames,
  monthsForPrice: number
) => {
  switch (subscription) {
    case ProductNames.SemaglutideMonthly:
      return MembershipPrice.monthly;
    default: //Membership.MultiMonthSubscription:
      return MembershipPrice.monthly * monthsForPrice;
  }
};

export const getTotalPriceBeforeTaxWithoutDiscountLegacy = (
  subscription: Membership,
  monthsForPrice: number
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return MembershipPrice.monthly * monthsForPrice;
    default: //Membership.MultiMonthSubscription:
      return MembershipPrice.monthly * monthsForPrice;
  }
};

export const getTotalPriceBeforeTaxWithoutDiscount = (
  subscription: Membership | ProductNames,
  monthsForPrice: number
) => {
  switch (subscription) {
    case ProductNames.SemaglutideBundleMonthly:
      return MembershipPriceSB.monthly;
    case ProductNames.InsuranceMounjaro:
      return AffirmPrice.brandedMedsMonthly;
    default: //Membership.MultiMonthSubscription:
      return MembershipPriceSB.monthly * monthsForPrice;
  }
};

export const formatPrice = (
  amount: number,
  includeDecimals: boolean = true,
  currency: string = "USD",
  locale: string = "en-US"
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: includeDecimals ? 2 : 0,
    maximumFractionDigits: includeDecimals ? 2 : 0,
  }).format(amount);
};

export const getRedirectUrl = () => {
  const currentUrl = new URL(window.location.href);
  currentUrl.pathname = CheckoutRoutes.Account;
  return currentUrl.toString();
};
